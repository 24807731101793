import Vue from 'vue';
import {
  AutoComplete,
  Badge,
  Breadcrumb,
  Button,
  Calendar,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Icon,
  Input,
  InputNumber,
  Form,
  FormModel,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Result,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Switch,
  Tabs,
  Timeline,
  Tooltip,
  Upload,
} from 'ant-design-vue';

Vue.use(AutoComplete);
Vue.use(Badge);
Vue.use(Breadcrumb);
Vue.use(Button);
Vue.use(Calendar);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(ConfigProvider);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Result);
Vue.use(Radio);
Vue.use(Row);
Vue.use(Select);
Vue.use(Spin);
Vue.use(Statistic);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(Timeline);
Vue.use(Tooltip);
Vue.use(Upload);
