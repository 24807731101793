import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';

export class CreateSigningPhysicianStatusRequest extends AddDictionaryItemRequest {
  public readonly triggersCaseHold: boolean;

  constructor(args: CreateSigningPhysicianStatusRequest) {
    super(args);
    this.triggersCaseHold = args.triggersCaseHold;
  }
}

export class EditSigningPhysicianStatusRequest extends EditDictionaryItemRequest {
  public readonly triggersCaseHold: boolean;

  constructor(args: EditSigningPhysicianStatusRequest) {
    super(args);
    this.triggersCaseHold = args.triggersCaseHold;
  }
}
