import { DateTimeInfo, IsDateTimeInfo } from '../dates';

export class FuneralHome {
  public funeralHomeId?: string;

  public name?: string;

  @IsDateTimeInfo()
  public dateNotified?: DateTimeInfo;

  public authorizedBy?: string;

  public organizationNotifiedBy?: string;

  public details?: string;

  constructor(args?: FuneralHome) {
    this.funeralHomeId = args?.funeralHomeId;
    this.name = args?.name;
    this.dateNotified = args?.dateNotified
      ? new DateTimeInfo(args?.dateNotified)
      : undefined;
    this.authorizedBy = args?.authorizedBy;
    this.organizationNotifiedBy = args?.organizationNotifiedBy;
    this.details = args?.details;
  }
}
