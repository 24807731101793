import { render, staticRenderFns } from "./transition-roll-in-up-out-down.vue?vue&type=template&id=7fb3ceca&"
import script from "./transition-roll-in-up-out-down.vue?vue&type=script&lang=ts&"
export * from "./transition-roll-in-up-out-down.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports