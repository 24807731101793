import { Type } from 'class-transformer';
import { FormatDate, IsFormatDate } from '../../dates';
import { CaseAction } from '../case-action';
import { CaseNotice } from '../notice';
import { CaseTypeDictionaryItem, DictionaryItem } from '../../dictionaries';
import { CustodyStatus } from '../custody-status';
import { CaseSummaryTags } from './tags';
import { CaseSummaryInsights } from './insights';
import { OriginLocationSummary } from './origin-location-summary';

export class CaseSummary {
  public readonly id!: string;

  public readonly isOpen!: boolean;

  public readonly custodyStatus!: CustodyStatus;

  public readonly decedentName?: string;

  public readonly decedentAlias?: string;

  public get displayName(): string {
    const { decedentName: name, decedentAlias: alias } = this;
    return name || alias
      ? name + (alias ? ` (${alias})` : '')
      : 'Unidentified Decedent';
  }

  public readonly decedentMedicalRecordNumber?: string;

  @IsFormatDate
  public readonly decedentDateOfBirth?: FormatDate;

  @IsFormatDate
  public readonly decedentExpirationDate?: FormatDate;

  @Type(() => DictionaryItem)
  public readonly gender?: DictionaryItem;

  public readonly bodyboxId?: string;

  public readonly bodybox?: string;

  public readonly storageLabel?: string;

  public readonly storageLocation?: string;

  public readonly originLocation?: OriginLocationSummary;

  @Type(() => CaseTypeDictionaryItem)
  public readonly caseType?: CaseTypeDictionaryItem;

  @Type(() => CaseSummaryTags)
  public readonly tags!: CaseSummaryTags;

  @Type(() => CaseNotice)
  public readonly notices!: CaseNotice[];

  @Type(() => CaseSummaryInsights)
  public readonly insights!: CaseSummaryInsights;

  @Type(() => CaseAction)
  public readonly checkIn!: CaseAction;

  @Type(() => CaseAction)
  public readonly checkOut!: CaseAction;
}
