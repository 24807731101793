export const tableConfig = {
  styleRoot: {},
  wrapper: 'relative rounded-md border border-comet-200',
  base: 'min-w-full table-fixed',
  divide: 'divide-y divide-comet-200',
  thead: {
    base: 'bg-comet-50',
    sticky: 'top-0 sticky z-10 shadow-sm',
    scrollable: 'top-0 sticky z-10',
  },
  tfoot: {
    base: 'bg-comet-50',
    sticky: 'bottom-0 sticky z-10 shadow-sm',
    scrollable: 'bottom-0 sticky z-10',
    th: 'bg-comet-50 font-semibold text-sm px-3 py-3.5',
    td: 'bg-comet-50 font-semibold text-sm px-3 py-3.5',
  },
  tbody: 'divide-y divide-brand-100',
  tr: {
    base:
      'bg-white transition-colors duration-50 even:bg-gray-50 hover:bg-brand-50',
    selected: 'bg-gray-50',
    active: 'hover:bg-gray-50 cursor-pointer',
  },
  th: {
    base:
      'text-left rtl:text-right bg-comet-50 first:rounded-tl-md last:rounded-tr-md transition duration-100',
    padding: 'px-3 py-3.5',
    color: 'text-brand-800',
    font: 'font-semibold',
    size: 'text-sm',
    stuck: 'first:rounded-tl-none last:rounded-tr-none',
    sorted: '!bg-brand-100',
  },
  td: {
    base: 'whitespace-nowrap',
    padding: 'px-3 py-4',
    color: 'text-gray-600',
    font: '',
    size: 'text-sm',
  },
  loadingState: {
    wrapper:
      'flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14 bg-white',
    label: 'text-sm text-center text-gray-500 font-medium',
    icon: 'w-6 h-6 mx-auto text-gray-400 mb-2 animate-spin',
  },
  emptyState: {
    wrapper:
      'flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14 bg-white',
    label: 'text-sm text-center text-gray-500 font-medium',
    icon: 'w-6 h-6 mx-auto text-gray-400 mb-2',
  },
  expand: {
    icon: 'transform transition-transform duration-200',
    class: '',
  },
  progress: {
    wrapper: 'absolute inset-x-0 -bottom-[0.5px] p-0',
  },
  default: {
    sortAscIcon: 'sort-ascending',
    sortDescIcon: 'sort-descending',
    sortButton: {
      icon: 'arrows-down-up',
      trailing: true,
      square: true,
      color: 'gray',
      variant: 'ghost',
      class:
        'flex items-center gap-1 font-semibold text-sm rounded-md hover:bg-brand-500 hover:bg-opacity-10 p-1 -m-1',
    },
    expandButton: {
      icon: 'caret-down',
      color: 'gray' as const,
      variant: 'ghost' as const,
      size: 'xs' as const,
      class: '-my-1.5 align-middle',
    },
    checkbox: {
      color: 'brand' as const,
    },
    progress: {
      // color: 'brand' as const,
      animation: 'carousel' as const,
    },
    loadingState: {
      icon: 'spinner-gap',
      label: 'Loading...',
    },
    emptyState: {
      icon: 'database',
      label: 'No items',
    },
  },
};
