import { EventCategory } from './event-category';
import { EventType } from './event-type';

export const EventHierarchy = {
  [EventCategory.UserAccess]: [
    EventType.CaseViewed,
    EventType.DocumentDownloaded,
  ],
  [EventCategory.Integration]: [EventType.IntegrationMessageReceived],
  [EventCategory.CaseGeneral]: [
    EventType.CaseCreated,
    EventType.CaseUpdated,
    EventType.CaseOpened,
    EventType.CaseClosed,
    EventType.CaseTouched,
  ],
  [EventCategory.CaseHold]: [
    EventType.CaseHoldAdded,
    EventType.CaseHoldRemoved,
  ],
  [EventCategory.CaseNote]: [EventType.CaseNoteAdded],
  [EventCategory.DecedentAutopsy]: [
    EventType.AutopsyStatusUpdated,
    EventType.AutopsyCompleted,
  ],
  [EventCategory.DecedentDonationProcurement]: [
    EventType.DonationProcurementStatusUpdated,
    EventType.DonationProcurementCompleted,
  ],
  [EventCategory.DecedentFuneralHome]: [EventType.FuneralHomeNotified],
  [EventCategory.DecedentNextOfKin]: [
    EventType.NextOfKinIdentified,
    EventType.NextOfKinNotified,
  ],
  [EventCategory.DecedentMedicolegalInvestigation]: [
    EventType.MedicolegalInvestigationStatusUpdated,
    EventType.MedicolegalInvestigationCompleted,
  ],
  [EventCategory.DecedentTransport]: [
    EventType.DecedentCheckedIn,
    EventType.DecedentCheckedOut,
    EventType.DecedentTransferred,
  ],
} as const;
