
import type { VNode, VNodeData } from 'vue';
import {
  defineComponent,
  h,
  onMounted,
  onBeforeUnmount,
  PropType,
  ref,
  Ref,
  getCurrentInstance,
  computed,
} from '@nuxtjs/composition-api';
import { useMutationObserver, useResizeObserver } from '@vueuse/core';
import WbMenuButton from '~/app/components/ui/menu-button/menu-button.vue';

export interface OverflowMenuClasses {
  root?: string;
  overflow?: string;
  button?: string;
  // menu?: string;
}

export default defineComponent({
  name: 'OverflowMenu',
  props: {
    as: {
      type: String,
      required: false,
      default: 'div',
    },
    classes: {
      type: Object as PropType<OverflowMenuClasses>,
      required: false,
      default: () => ({}),
    },
    alignMenu: {
      type: String as PropType<'left' | 'right'>,
      required: false,
      default: 'right',
    },
    menuProps: {
      type: Object as PropType<Record<string, any>>,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { attrs, slots }) {
    const overflowingNodes = ref<VNode[]>([]) as Ref<VNode[]>;
    const containerRef = ref<HTMLElement>();
    const maxChildHeight = ref(0);

    const menuRef = computed<InstanceType<typeof WbMenuButton>>(
      () => instance?.$refs.menu as InstanceType<typeof WbMenuButton>
    );

    const menu = computed(() => ({
      open: () => menuRef.value?.show(),
      close: () => menuRef.value?.hide(),
    }));

    const nodes = computed(
      () =>
        // Provide the menu object to the default slot prop
        (slots.default?.({ menu: menu.value }) || []).map((node, index) =>
          setDataAttr(node, 'data-node-id', index)
        ) as VNode[]
    );

    function findNode(nodeId: string) {
      return nodes.value.find(
        (node) => node.data?.attrs?.['data-node-id']?.toString() === nodeId
      );
    }

    function updateMaxHeight() {
      if (!containerRef.value) return;

      const children = Array.from(containerRef.value.children) as HTMLElement[];
      const heights = children.map((child) => {
        // Get the actual rendered height including padding and borders
        const styles = window.getComputedStyle(child);
        const height = child.offsetHeight;
        const marginTop = parseFloat(styles.marginTop);
        const marginBottom = parseFloat(styles.marginBottom);
        return height + marginTop + marginBottom;
      });

      maxChildHeight.value = Math.max(0, ...heights);
    }

    function updateOverflowingNodes() {
      if (!containerRef.value) return;

      updateMaxHeight();

      const container = containerRef.value;
      const containerChildren = Array.from(container.children) as HTMLElement[];

      const overflowedEls = containerChildren.filter(
        (child) => child.offsetTop > container.offsetTop
      );

      overflowingNodes.value = overflowedEls.reduce((nodes, el) => {
        const nodeId = el.getAttribute('data-node-id');
        const node = nodeId ? findNode(nodeId) : undefined;
        return node ? [...nodes, node] : nodes;
      }, [] as VNode[]);
    }

    const { stop: stopResize } = useResizeObserver(
      containerRef,
      updateOverflowingNodes
    );

    const { stop: stopMutation } = useMutationObserver(
      containerRef,
      () => {
        updateMaxHeight();
      },
      {
        childList: true, // Watch for changes in direct children
        subtree: true, // Watch for changes in descendants
        attributes: true, // Watch for changes in attributes
        characterData: true, // Watch for changes in text content
      }
    );

    onBeforeUnmount(() => {
      stopResize();
      stopMutation();
    });

    const instance = getCurrentInstance()?.proxy;
    onMounted(() => {
      containerRef.value = instance?.$refs.container as HTMLElement;
      updateOverflowingNodes();
    });

    function setDataAttr(node: VNode, attr: string, value: any) {
      return {
        ...node,
        data: {
          ...node.data,
          attrs: { ...node.data?.attrs, [attr]: value },
        },
      };
    }

    function isOverflowing(node: VNode) {
      return overflowingNodes.value.some(
        (n) =>
          n.data?.attrs?.['data-node-id'] === node.data?.attrs?.['data-node-id']
      );
    }

    function normalizeStyle(
      style: string | object | object[] | undefined
    ): Record<string, any> {
      if (!style) return {};

      if (typeof style === 'string') {
        // Convert CSS string to object
        return style.split(';').reduce((acc, current) => {
          const [key, value] = current.split(':').map((s) => s.trim());
          if (key && value) {
            // Convert kebab-case to camelCase
            const camelKey = key.replace(/-([a-z])/g, (g) =>
              g[1].toUpperCase()
            );
            acc[camelKey] = value;
          }
          return acc;
        }, {} as Record<string, any>);
      }

      if (Array.isArray(style)) {
        // Merge array of styles into single object
        return (style as object[]).reduce(
          (acc, curr) => ({ ...acc, ...normalizeStyle(curr) }),
          {} as Record<string, any>
        );
      }

      return style as Record<string, any>;
    }

    return () => {
      const nodesOutOfView = [...overflowingNodes.value];
      const nodesInView = [...nodes.value]
        .map<VNode>((node) =>
          setDataAttr(node, 'data-overflowed', isOverflowing(node))
        )
        .map<VNode>((node) => ({
          ...node,
          data: {
            ...node.data,
            style: {
              ...normalizeStyle(node.data?.style),
              display: 'inline-flex',
            },
            attrs: {
              ...node.data?.attrs,
              tabIndex: isOverflowing(node) ? -1 : undefined,
            },
          } as VNodeData,
        }));

      const OverflowContainer = h(
        props.as,
        {
          ref: 'container',
          // flex-1 makes it so that the More Menu is always at the end of the container, remove it to allow it to flow with the rest of the content
          class: ['flex flex-wrap overflow-hidden', props.classes.overflow],
          style: { maxHeight: `${maxChildHeight.value}px` },
          attrs: { ...attrs },
        },
        nodesInView
      );

      const MenuButton = h(WbMenuButton, {
        ref: 'menu',
        props: props.menuProps,
        class: 'flex-shrink-0',
        scopedSlots: {
          trigger: ({ triggerId, menuId, isOpen, toggleMenu }) =>
            slots.button?.({ triggerId, menuId, isOpen, toggleMenu }) ||
            h(
              'button',
              {
                attrs: { 'aria-label': 'Show more options' },
                on: { click: () => toggleMenu() },
                class: props.classes.button,
              },
              slots['menu:trigger:content']?.({
                triggerId,
                menuId,
                isOpen,
                toggleMenu,
              }) || [
                h('wb-icon', {
                  props: { name: 'dots-three', type: 'bold' },
                }),
              ]
            ),
          default: ({ hide }) => [
            ...(slots['menu:header']?.({ close: hide }) || []),
            h('div', { class: '-m-2 flex flex-col gap-2' }, nodesOutOfView),
            ...(slots['menu:footer']?.({ close: hide }) || []),
          ],
        },
      });

      const nodeArrangement = [
        OverflowContainer,
        nodesOutOfView.length > 0 ? MenuButton : null,
      ];

      return h(
        'div',
        { class: ['relative flex', props.classes.root] },
        props.alignMenu === 'right'
          ? nodeArrangement
          : nodeArrangement.reverse()
      );
    };
  },
});
