import { render, staticRenderFns } from "./editor-toolbar.vue?vue&type=template&id=ebf3dba4&scoped=true&"
import script from "./editor-toolbar.vue?vue&type=script&lang=ts&"
export * from "./editor-toolbar.vue?vue&type=script&lang=ts&"
import style0 from "./editor-toolbar.vue?vue&type=style&index=0&id=ebf3dba4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebf3dba4",
  null
  
)

export default component.exports