import { DictionaryItem } from './dictionary-item';

export class SigningPhysicianStatusDictionaryItem extends DictionaryItem {
  public readonly triggersCaseHold!: boolean;

  constructor(args?: SigningPhysicianStatusDictionaryItem) {
    super(args);
    this.triggersCaseHold = args?.triggersCaseHold ?? false;
  }
}
