import { render, staticRenderFns } from "./filter-pill.vue?vue&type=template&id=0f89f76b&scoped=true&"
import script from "./filter-pill.vue?vue&type=script&lang=ts&"
export * from "./filter-pill.vue?vue&type=script&lang=ts&"
import style0 from "./filter-pill.vue?vue&type=style&index=0&id=0f89f76b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f89f76b",
  null
  
)

export default component.exports