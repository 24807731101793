import { Case } from '~/app/models';
export interface ICaseDetailsState {
  caseId: string | null;
  case: Case | null;
  identityVerified: boolean;
}

export const state = (): ICaseDetailsState => ({
  caseId: null,
  case: null,
  identityVerified: false,
});
