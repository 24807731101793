import { plainToInstance } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import {
  AddDictionaryItemRequest,
  EditDictionaryItemRequest,
  DeleteDictionaryItemRequest,
} from '../../requests/dictionaries';
import {
  DictionaryItem,
  OrganizationDictionaryItem,
  AssociateOrganizationType,
  SystemTagDictionaryItem,
  ComputedTagDictionaryItem,
  NextOfKinTypeDictionaryItem,
  OriginLocationDictionaryItem,
  DictionaryType,
  CaseTypeDictionaryItem,
  CaseServiceRequirementDictionaryItem,
  CaseServiceStatusDictionaryItem,
  FluidLocationDictionaryItem,
  SigningPhysicianStatusDictionaryItem,
} from '~/app/models';

type DictionaryConstructorType = DictionaryType | 'associateOrganization';

function ToCamelCase(input: string) {
  return input.length === 0
    ? ''
    : input.charAt(0).toLowerCase() + input.slice(1);
}

export const createDictionariesApi: PartialApiFactory<
  IApiFactory,
  'dictionaries'
> = (api) => {
  const ctors: {
    [slug in DictionaryConstructorType]?: Constructor<DictionaryItem>;
  } = {
    associateOrganization: OrganizationDictionaryItem,
    autopsyVendor: OrganizationDictionaryItem,
    caseServiceRequirement: CaseServiceRequirementDictionaryItem,
    caseServiceStatus: CaseServiceStatusDictionaryItem,
    caseType: CaseTypeDictionaryItem,
    causeOfDeath: DictionaryItem,
    ethnicity: DictionaryItem,
    fluidLocation: FluidLocationDictionaryItem,
    funeralHome: OrganizationDictionaryItem,
    gender: DictionaryItem,
    infectiousDiseaseType: DictionaryItem,
    medicalExaminer: OrganizationDictionaryItem,
    nextOfKinType: NextOfKinTypeDictionaryItem,
    originLocation: OriginLocationDictionaryItem,
    personalBelongingLocation: DictionaryItem,
    physicianType: DictionaryItem,
    race: DictionaryItem,
    reasonForEngagement: DictionaryItem,
    serviceLine: DictionaryItem,
    signingPhysicianStatus: SigningPhysicianStatusDictionaryItem,
    tag: SystemTagDictionaryItem,
    tissueBank: OrganizationDictionaryItem,
  };

  return {
    async getAsync<TReturn extends DictionaryItem = DictionaryItem>(
      slug: DictionaryType
    ) {
      const items = await api.getAsync<TReturn[]>(`Dictionaries/${slug}`);

      const typeKey = ToCamelCase(slug) as DictionaryType;

      return items.map((item) =>
        plainToInstance(ctors[typeKey] || DictionaryItem, item)
      ) as TReturn[];
    },
    async getByIdAsync<TReturn extends DictionaryItem = DictionaryItem>(
      slug: DictionaryType,
      id: string
    ) {
      if (!slug) throw new Error('slug is required');
      if (!id) throw new Error('id is required');

      const item = await api.getAsync<TReturn>(`Dictionaries/${slug}/${id}`);

      const typeKey = ToCamelCase(slug) as DictionaryType;

      return plainToInstance(ctors[typeKey] || DictionaryItem, item) as TReturn;
    },
    async addAsync<
      TReturn extends DictionaryItem = DictionaryItem,
      TRequest extends AddDictionaryItemRequest = AddDictionaryItemRequest
    >(slug: DictionaryType, request: TRequest) {
      const vendor = await api.postAsync<TReturn>(
        `Dictionaries/${slug}`,
        request
      );

      const typeKey = ToCamelCase(slug) as DictionaryType;

      return plainToInstance(
        ctors[typeKey] || DictionaryItem,
        vendor
      ) as TReturn;
    },
    async editAsync<
      TReturn extends DictionaryItem = DictionaryItem,
      TRequest extends EditDictionaryItemRequest = EditDictionaryItemRequest
    >(slug: DictionaryType, request: TRequest) {
      const vendor = await api.putAsync<TReturn>(
        `Dictionaries/${slug}/${request.id}`,
        request
      );

      const typeKey = ToCamelCase(slug) as DictionaryType;

      return plainToInstance(
        ctors[typeKey] || DictionaryItem,
        vendor
      ) as TReturn;
    },
    async deleteAsync<
      TRequest extends DeleteDictionaryItemRequest = DeleteDictionaryItemRequest
    >(slug: string, { entryId }: TRequest) {
      await api.deleteAsync(`Dictionaries/${slug}/${entryId}`);
    },

    async getGendersAsync() {
      const items = await api.getAsync<DictionaryItem[]>('Dictionaries/Gender');
      return plainToInstance(DictionaryItem, items);
    },
    async getGenderAsync(entryId) {
      const gender = await api.getAsync<DictionaryItem>(
        `Dictionaries/Gender/${entryId}`
      );
      return plainToInstance(DictionaryItem, gender);
    },
    async getSystemTagsAsync() {
      const items = await api.getAsync<SystemTagDictionaryItem[]>(
        'Dictionaries/Tag'
      );
      return plainToInstance(SystemTagDictionaryItem, items);
    },
    async getSystemTagAsync(entryId) {
      const tag = await api.getAsync<SystemTagDictionaryItem>(
        `Dictionaries/Tag/${entryId}`
      );
      return plainToInstance(SystemTagDictionaryItem, tag);
    },
    async getComputedTagsAsync() {
      const items = await api.getAsync<ComputedTagDictionaryItem[]>(
        'Dictionaries/Tag/Computed'
      );
      return plainToInstance(ComputedTagDictionaryItem, items);
    },
    async getPersonalBelongingLocationsAsync() {
      const items = await api.getAsync<DictionaryItem[]>(
        'Dictionaries/DecedentPropertyLocation'
      );
      return plainToInstance(DictionaryItem, items);
    },
    async getPersonalBelongingLocationAsync(entryId) {
      const location = await api.getAsync<DictionaryItem>(
        `Dictionaries/DecedentPropertyLocation/${entryId}`
      );
      return plainToInstance(DictionaryItem, location);
    },
    async getOriginLocationsAsync() {
      const items = await api.getAsync<OriginLocationDictionaryItem[]>(
        'Dictionaries/OriginLocation'
      );
      return plainToInstance(OriginLocationDictionaryItem, items);
    },
    async getOriginLocationAsync(entryId) {
      const location = await api.getAsync<OriginLocationDictionaryItem>(
        `Dictionaries/OriginLocation/${entryId}`
      );
      return plainToInstance(OriginLocationDictionaryItem, location);
    },
    async getAssociateOrganizationsAsync() {
      const items = await api.getAsync<OrganizationDictionaryItem[]>(
        'Dictionaries/AssociateOrganization'
      );
      return plainToInstance(OrganizationDictionaryItem, items);
    },
    async getOrganBanksAsync() {
      const items = await api
        .getAsync<OrganizationDictionaryItem[]>(
          'Dictionaries/AssociateOrganization'
        )
        .then((data) =>
          data.filter(
            (item) => item.type === AssociateOrganizationType.OrganBank
          )
        );

      return plainToInstance(OrganizationDictionaryItem, items);
    },
    async getOrganBankAsync(entryId) {
      const organBank = await api.getAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );

      return plainToInstance(OrganizationDictionaryItem, organBank);
    },
    async getMedicalExaminersAsync() {
      const items = await api
        .getAsync<OrganizationDictionaryItem[]>(
          'Dictionaries/AssociateOrganization'
        )
        .then((data) =>
          data.filter(
            (item) => item.type === AssociateOrganizationType.MedicalExaminer
          )
        );

      return plainToInstance(OrganizationDictionaryItem, items);
    },
    async getMedicalExaminerAsync(entryId) {
      const medicalExaminer = await api.getAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );

      return plainToInstance(OrganizationDictionaryItem, medicalExaminer);
    },
    async getFuneralHomesAsync() {
      const items = await api
        .getAsync<OrganizationDictionaryItem[]>(
          'Dictionaries/AssociateOrganization'
        )
        .then((data) =>
          data.filter(
            (item) => item.type === AssociateOrganizationType.FuneralHome
          )
        );
      return plainToInstance(OrganizationDictionaryItem, items);
    },
    async getFuneralHomeAsync(entryId) {
      const funeralHome = await api.getAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
      return plainToInstance(OrganizationDictionaryItem, funeralHome);
    },
    async getAutopsyVendorsAsync() {
      const items = await api
        .getAsync<OrganizationDictionaryItem[]>(
          'Dictionaries/AssociateOrganization'
        )
        .then((data) =>
          data.filter(
            (item) => item.type === AssociateOrganizationType.AutopsyVendor
          )
        );
      return plainToInstance(OrganizationDictionaryItem, items);
    },
    async getAutopsyVendorAsync(entryId) {
      const vendor = await api.getAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
      return plainToInstance(OrganizationDictionaryItem, vendor);
    },
    async getNextOfKinTypesAsync() {
      const items = await api.getAsync<NextOfKinTypeDictionaryItem[]>(
        'Dictionaries/NextOfKinType'
      );

      return plainToInstance(NextOfKinTypeDictionaryItem, items);
    },
    async getInfectiousDiseaseTypesAsync() {
      const items = await api.getAsync<DictionaryItem[]>(
        'Dictionaries/InfectiousDiseaseType'
      );

      return plainToInstance(DictionaryItem, items);
    },
    async getInfectiousDiseaseTypeAsync(entryId) {
      const type = await api.getAsync<DictionaryItem>(
        `Dictionaries/InfectiousDiseaseType/${entryId}`
      );

      return plainToInstance(DictionaryItem, type);
    },
    async getNextOfKinTypeAsync(entryId) {
      const type = await api.getAsync<NextOfKinTypeDictionaryItem>(
        `Dictionaries/NextOfKinType/${entryId}`
      );

      return plainToInstance(NextOfKinTypeDictionaryItem, type);
    },
    async getCaseTypesAsync() {
      const items = await api.getAsync<CaseTypeDictionaryItem[]>(
        'Dictionaries/CaseType'
      );

      return plainToInstance(CaseTypeDictionaryItem, items);
    },
    async getCaseTypeAsync(entryId: string) {
      const type = await api.getAsync<CaseTypeDictionaryItem>(
        `Dictionaries/CaseType/${entryId}`
      );

      return plainToInstance(CaseTypeDictionaryItem, type);
    },

    async addAutopsyVendorAsync(request) {
      const vendor = await api.postAsync<OrganizationDictionaryItem>(
        'Dictionaries/AssociateOrganization',
        request
      );

      return plainToInstance(OrganizationDictionaryItem, vendor);
    },
    async editAutopsyVendorAsync(request) {
      const vendor = await api.putAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${request.id}`,
        request
      );

      return plainToInstance(OrganizationDictionaryItem, vendor);
    },
    async deleteAutopsyVendorAsync({ entryId }) {
      await api.deleteAsync<void>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
    },
    async addFuneralHomeAsync(request) {
      const funeralHome = await api.postAsync<OrganizationDictionaryItem>(
        'Dictionaries/AssociateOrganization',
        request
      );

      return plainToInstance(OrganizationDictionaryItem, funeralHome);
    },
    async editFuneralHomeAsync(request) {
      const funeralHome = await api.putAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${request.id}`,
        request
      );

      return plainToInstance(OrganizationDictionaryItem, funeralHome);
    },
    async deleteFuneralHomeAsync({ entryId }) {
      await api.deleteAsync<DictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
    },
    async addGenderAsync(request) {
      const gender = await api.postAsync<DictionaryItem>(
        'Dictionaries/Gender',
        request
      );

      return plainToInstance(DictionaryItem, gender);
    },
    async editGenderAsync(request) {
      const gender = await api.putAsync<DictionaryItem>(
        `Dictionaries/Gender/${request.id}`,
        request
      );

      return plainToInstance(DictionaryItem, gender);
    },
    async deleteGenderAsync({ entryId }) {
      await api.deleteAsync<DictionaryItem>(`Dictionaries/Gender/${entryId}`);
    },
    async addOrganBankAsync(request) {
      const organBank = await api.postAsync<OrganizationDictionaryItem>(
        'Dictionaries/AssociateOrganization',
        request
      );

      return plainToInstance(DictionaryItem, organBank);
    },
    async editOrganBankAsync(request) {
      const organBank = await api.putAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${request.id}`,
        request
      );

      return plainToInstance(OrganizationDictionaryItem, organBank);
    },
    async deleteOrganBankAsync({ entryId }) {
      await api.deleteAsync<DictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
    },
    async addMedicalExaminerAsync(request) {
      const medicalExaminer = await api.postAsync<OrganizationDictionaryItem>(
        'Dictionaries/AssociateOrganization',
        request
      );

      return plainToInstance(DictionaryItem, medicalExaminer);
    },
    async editMedicalExaminerAsync(request) {
      const organBank = await api.putAsync<OrganizationDictionaryItem>(
        `Dictionaries/AssociateOrganization/${request.id}`,
        request
      );

      return plainToInstance(OrganizationDictionaryItem, organBank);
    },
    async deleteMedicalExaminerAsync({ entryId }) {
      await api.deleteAsync<DictionaryItem>(
        `Dictionaries/AssociateOrganization/${entryId}`
      );
    },
    async addPersonalBelongingLocationAsync(request) {
      const location = await api.postAsync<DictionaryItem>(
        'Dictionaries/DecedentPropertyLocation',
        request
      );

      return plainToInstance(DictionaryItem, location);
    },
    async editPersonalBelongingLocationAsync(request) {
      const location = await api.putAsync<DictionaryItem>(
        `Dictionaries/DecedentPropertyLocation/${request.id}`,
        request
      );

      return plainToInstance(DictionaryItem, location);
    },
    async deletePersonalBelongingLocationAsync({ entryId }) {
      await api.deleteAsync<DictionaryItem>(
        `Dictionaries/DecedentPropertyLocation/${entryId}`
      );
    },
    async addOriginLocationAsync(request) {
      const location = await api.postAsync<OriginLocationDictionaryItem>(
        'Dictionaries/OriginLocation',
        request
      );

      return plainToInstance(OriginLocationDictionaryItem, location);
    },
    async editOriginLocationAsync(request) {
      const location = await api.putAsync<OriginLocationDictionaryItem>(
        `Dictionaries/OriginLocation/${request.id}`,
        request
      );

      return plainToInstance(OriginLocationDictionaryItem, location);
    },
    async deleteOriginLocationAsync({ entryId }) {
      await api.deleteAsync<OriginLocationDictionaryItem>(
        `Dictionaries/OriginLocation/${entryId}`
      );
    },
    async addTagAsync(request) {
      const tag = await api.postAsync<SystemTagDictionaryItem>(
        'Dictionaries/Tag',
        request
      );

      return plainToInstance(SystemTagDictionaryItem, tag);
    },
    async editTagAsync(request) {
      const tag = await api.putAsync<SystemTagDictionaryItem>(
        `Dictionaries/Tag/${request.id}`,
        request
      );

      return plainToInstance(SystemTagDictionaryItem, tag);
    },
    async deleteTagAsync({ entryId }) {
      await api.deleteAsync(`Dictionaries/Tag/${entryId}`);
    },
    async addNextOfKinTypeAsync(request) {
      const type = await api.postAsync<NextOfKinTypeDictionaryItem>(
        'Dictionaries/NextOfKinType',
        request
      );

      return plainToInstance(NextOfKinTypeDictionaryItem, type);
    },
    async editNextOfKinTypeAsync(request) {
      const type = await api.putAsync<NextOfKinTypeDictionaryItem>(
        `Dictionaries/NextOfKinType/${request.id}`,
        request
      );

      return plainToInstance(NextOfKinTypeDictionaryItem, type);
    },
    async deleteNextOfKinTypeAsync({ entryId }) {
      await api.deleteAsync(`Dictionaries/NextOfKinType/${entryId}`);
    },
    async addInfectiousDiseaseTypeAsync(request) {
      const type = await api.postAsync<DictionaryItem>(
        'Dictionaries/InfectiousDiseaseType',
        request
      );

      return plainToInstance(DictionaryItem, type);
    },
    async editInfectiousDiseaseTypeAsync(request) {
      const type = await api.putAsync<DictionaryItem>(
        `Dictionaries/InfectiousDiseaseType/${request.id}`,
        request
      );

      return plainToInstance(DictionaryItem, type);
    },
    async deleteInfectiousDiseaseTypeAsync({ entryId }) {
      await api.deleteAsync(`Dictionaries/InfectiousDiseaseType/${entryId}`);
    },
    async addCaseTypeAsync(request) {
      const type = await api.postAsync<CaseTypeDictionaryItem>(
        'Dictionaries/CaseType',
        request
      );

      return plainToInstance(DictionaryItem, type);
    },
    async editCaseTypeAsync(request) {
      const type = await api.putAsync<CaseTypeDictionaryItem>(
        `Dictionaries/CaseType/${request.id}`,
        request
      );

      return plainToInstance(DictionaryItem, type);
    },
    async deleteCaseTypeAsync({ entryId }) {
      await api.deleteAsync(`Dictionaries/CaseType/${entryId}`);
    },
  };
};
