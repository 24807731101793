import { plainToInstance } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { OutboxCaseSummary, PageResult } from '~/app/models';

export const createOutboxApi: PartialApiFactory<IApiFactory, 'outbox'> = (
  api
) => {
  return {
    async getSummariesAsync(request) {
      const result = await api.getAsync<PageResult<OutboxCaseSummary>>(
        'Outbox/V2',
        { params: request as Indexable }
      );

      return plainToInstance(PageResult, {
        ...result,
        data: plainToInstance(OutboxCaseSummary, result.data),
      }) as PageResult<OutboxCaseSummary>;
    },
  };
};
