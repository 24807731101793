





































































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { Editor } from '@tiptap/vue-2';

export type FormatType =
  | 'bold'
  | 'italic'
  | 'strike'
  | 'underline'
  | 'highlight';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface ContentEditorTools {
  format: FormatType[] | boolean;
  color: boolean;
  heading: HeadingLevel[] | boolean;
  link: boolean;
}

const DEFAULT_TOOLS: ContentEditorTools = {
  format: ['bold', 'italic', 'underline', 'highlight'],
  color: true,
  heading: false,
  link: true,
};

export default defineComponent({
  name: 'ContentEditorToolbar',
  props: {
    editor: {
      type: Object as PropType<Editor>,
      required: true,
    },
    tools: {
      type: Object as PropType<ContentEditorTools>,
      default: () => DEFAULT_TOOLS,
    },
  },
  setup(props) {
    const showHighlight = computed(
      () =>
        props.tools.format === true ||
        (Array.isArray(props.tools.format) &&
          props.tools.format.includes('highlight'))
    );

    const showColorGroup = computed(
      () => props.tools.color || showHighlight.value
    );

    const showFormatGroup = computed(
      () => Array.isArray(props.tools.format) && props.tools.format.length > 0
    );

    const showHeadingGroup = computed(
      () =>
        props.tools.heading ||
        (Array.isArray(props.tools.heading) && props.tools.heading.length > 0)
    );

    const showLinkGroup = computed(() => props.tools.link);

    // #region Color

    function applyColor(color: string) {
      if (!props.editor) return;

      props.editor.chain().focus().setColor(color).run();
    }

    // #endregion

    // #region Text Formatting

    const formatTypes = computed(() =>
      typeof props.tools.format === 'boolean'
        ? (DEFAULT_TOOLS.format as FormatType[])
        : props.tools.format
    );

    function toggleFormat(type: FormatType) {
      if (!props.editor) return;

      const command = props.editor.chain().focus();

      switch (type) {
        case 'bold':
          command.toggleBold().run();
          break;
        case 'italic':
          command.toggleItalic().run();
          break;
        case 'strike':
          command.toggleStrike().run();
          break;
        case 'underline':
          command.toggleUnderline().run();
          break;
        case 'highlight':
          command.toggleHighlight({ color: '#fde68a' }).run();
          break;
      }
    }

    // #endregion

    // #region Heading

    const headingIcons = {
      1: 'text-h-one',
      2: 'text-h-two',
      3: 'text-h-three',
      4: 'text-h-four',
      5: 'text-h-five',
      6: 'text-h-six',
    } as const;

    function toggleHeading(level: HeadingLevel) {
      if (!props.editor) return;
      props.editor.chain().focus().toggleHeading({ level }).run();
    }

    const headingLevels = computed(() =>
      typeof props.tools.heading === 'boolean'
        ? ([1, 2, 3, 4, 5, 6] as HeadingLevel[])
        : props.tools.heading
    );

    // #endregion

    // #region Link

    function setLink() {
      if (!props.editor) return;
      const previousUrl = props.editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      // cancelled
      if (url === null) return;

      const command = props.editor.chain().focus();

      // empty
      if (url === '') {
        command.extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      command.extendMarkRange('link').setLink({ href: url }).run();
    }

    function unsetLink() {
      if (!props.editor) return;
      props.editor.chain().focus().unsetLink().run();
    }

    // #endregion

    return {
      // Features
      showColorGroup,
      showFormatGroup,
      showHeadingGroup,
      showLinkGroup,
      // Color
      applyColor,
      // Format
      formatTypes,
      toggleFormat,
      // Heading
      headingIcons,
      headingLevels,
      toggleHeading,
      // Link
      setLink,
      unsetLink,
    };
  },
});
