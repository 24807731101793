




























































































































import {
  computed,
  defineComponent,
  nextTick,
  ref,
  PropType,
} from '@nuxtjs/composition-api';
import consola from 'consola';
import { onClickOutside } from '@vueuse/core';
import {
  DateExpression,
  DatePicker as Calendar,
  DatePickerProps as CalendarProps,
} from 'v-calendar';
import DateTimePicker, {
  InputType,
  props as pickerProps,
} from './date-time-picker.vue';
import CalenderMenu, {
  DropdownProps,
} from './date-time-picker-calendar-menu.vue';
import { equals } from './utils';
import { DateOnly } from '~/app/models';
import { formatDate } from '~/app/models/dates/utils';
import { sleep } from '~/app/utils/async';

const console = consola.withTag('📅');
const DEBUG = process.env.NODE_ENV === 'development';

type CalendarMenuProps = Omit<DropdownProps, 'placement'>;

const disabledDateProps = {
  minDate: {
    type: Date as PropType<Date | undefined>,
    required: false,
  },
  maxDate: {
    type: Date as PropType<Date | undefined>,
    required: false,
  },
  disabledDates: {
    type: Array as PropType<DateExpression[] | undefined>,
    required: false,
  },
};

export default defineComponent({
  name: 'DateTimePickerCalendar',
  components: {
    CalenderMenu,
    DateTimePicker,
    VCalendar: Calendar,
  },
  props: {
    ...pickerProps,
    ...disabledDateProps,
    dropdown: {
      type: Object as PropType<CalendarMenuProps | undefined>,
      required: false,
      default: undefined,
    },
    calendar: {
      type: Object as PropType<CalendarProps | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: ['input', 'change'],
  setup(props, { emit }) {
    const menu = ref<InstanceType<typeof CalenderMenu>>();
    const picker = ref<InstanceType<typeof DateTimePicker>>();
    const calendarRef = ref<InstanceType<typeof Calendar>>();

    function isInComponent(el: HTMLElement | null | undefined) {
      const isInCalendar = calendarRef.value?.$el.contains(el as any);
      const isInPicker = picker.value?.$el.contains(el as any);
      return isInCalendar || isInPicker;
    }

    function emitDate(date?: Date | DateOnly) {
      emit('input', date);
      emit('change', date);
      hideMenu();
    }

    // #region Menu

    const menuEl = computed(() => menu.value?.$el as HTMLElement | undefined);
    const isOpen = computed(() => !!menu.value?.isShown);

    let inClickOutside: Promise<HTMLElement | undefined> | undefined;
    onClickOutside(menuEl, (event) => {
      inClickOutside = new Promise((resolve) => {
        if (!isOpen.value) return resolve(undefined);

        const target = ((event as unknown) as PointerEvent).target as
          | HTMLElement
          | undefined;

        if (isInComponent(target)) focusInput(lastPickerInput.value);
        else hideMenu();

        resolve(target);
      });
    });

    async function waitForClickOutsideAsync(defer = 100) {
      await sleep(defer);
      return inClickOutside ? await inClickOutside : undefined;
    }

    function hideMenu() {
      menu.value?.hide();
      // Reset the calendar view to the selected date
      calendarRef.value?.move(
        props.value instanceof DateOnly
          ? props.value.toDate()
          : props.value || new Date()
      );
    }

    // #endregion

    // #region Picker

    const lastPickerInput = ref<HTMLElement>();

    function onPickerInput(value: Date | DateOnly) {
      DEBUG && console.warn('🟢<-🖋️ EMIT', value);
      emitDate(value);
    }

    function onPickerFocus(event: FocusEvent, input: InputType) {
      if (['month', 'day', 'year'].includes(input)) menu.value?.show();
      else isOpen.value && hideMenu();
      emit('focus', event, input);
    }

    async function onPickerBlur(date: Date | DateOnly, event: FocusEvent) {
      const clickTarget = await waitForClickOutsideAsync();

      const sourceTarget = event.target as HTMLElement | undefined;
      lastPickerInput.value = sourceTarget;

      const blurTarget = event.relatedTarget as HTMLElement | undefined;

      const target = clickTarget || blurTarget;

      if (isInComponent(target)) return;

      hideMenu();
      emit('blur', date, event);
    }

    function focusPickerTime() {
      props.includeTime && focusInput(picker.value?.hourInput);
    }

    // #endregion

    // #region Calendar

    const calendarDate = computed(() => {
      if (!props.value) return null;
      return props.value instanceof DateOnly
        ? props.value.toDate()
        : props.value;
    });

    function tryMergeDateAndTime(value: Date | DateOnly): Date | DateOnly {
      const isDateTime = (v: any): v is Date =>
        v instanceof Date && !isNaN(v.getTime());

      const dateOnly = new DateOnly(value);

      if (props.includeTime) {
        if (!props.value || isDateTime(value)) return value;

        if (props.value instanceof DateOnly) return dateOnly;

        // Merge time from existing value with the newly selected DateOnly
        const dateTime = dateOnly.toDate();
        dateTime.setHours(props.value.getHours());
        dateTime.setMinutes(props.value.getMinutes());
        dateTime.setSeconds(props.value.getSeconds());
        return dateTime;
      }

      return dateOnly;
    }

    async function setDate(date?: Date | DateOnly) {
      if (equals(props.value, date)) return;

      // internalValue.value = date;
      picker.value?.set(date);

      if (!date) {
        // date value was cleared or unselected, reset focus to the beginning of the date inputs
        focusInput(picker.value?.monthInput);
        return;
      }

      const timeInputs: HTMLElement[] = picker.value
        ? ([
            picker.value.hourInput,
            picker.value.minuteInput,
            picker.value.ampmInput,
          ] as HTMLElement[])
        : [];

      if (
        lastPickerInput.value &&
        timeInputs.includes(lastPickerInput.value as HTMLElement)
      ) {
        // calendar was selected out of a time input, refocus it
        focusInput(lastPickerInput.value);
        return;
      }

      if (props.includeTime) focusPickerTime();
      else {
        await nextTick();
        const nextInput =
          picker.value?.clearBtn ||
          lastPickerInput.value ||
          picker.value?.monthInput;
        focusInput(nextInput);
      }
    }

    async function onCalendarInput(value?: Date) {
      const dateOnly = value ? new DateOnly(value) : undefined;
      const date = dateOnly ? tryMergeDateAndTime(dateOnly) : undefined;
      await setDate(date);
    }

    function focusInput(input?: HTMLElement | null) {
      input?.focus();
      // Highlight/preselect all text in the next input
      input instanceof HTMLInputElement && input.select();
    }

    function now() {
      return new Date();
    }

    async function setDateTimeNow() {
      await setDate(now());
    }

    async function setDateTimeToday() {
      const today = new DateOnly();
      await setDate(today);
    }

    function getTime(type: 'now' | 'today') {
      return formatDate(new Date(), {
        type: 'numerical',
        time: type === 'now',
        military: props.is24Hour,
      });
    }

    function getTooltipContainer(menuId: string) {
      return document.getElementById(menuId);
    }

    // #endregion

    return {
      menu,
      // Picker
      picker,
      onPickerInput,
      onPickerFocus,
      onPickerBlur,
      // Calendar
      calendarRef,
      calendarDate,
      onCalendarInput,
      setDateTimeNow,
      setDateTimeToday,
      now,
      getTime,
      getTooltipContainer,
    };
  },
});
