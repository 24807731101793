import Vue, { VueConstructor, PluginObject } from 'vue';
import WbOverflow from './overflow.vue';
import WbOverflowMenu from './overflow-menu.vue';

const overflow: VueConstructor<Vue> & PluginObject<void> = WbOverflow as any;
overflow.install = function (vue) {
  vue.component('WbOverflow', WbOverflow);
  vue.component('WbOverflowMenu', WbOverflowMenu);
};

export { WbOverflow, WbOverflowMenu };
export default overflow;
