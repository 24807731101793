import Vue, { VueConstructor, PluginObject } from 'vue';
import WbEditor from './editor.vue';
import WbEditorContent from './editor-content.vue';

const editor: VueConstructor<Vue> & PluginObject<void> = WbEditor as any;
editor.install = function (vue) {
  vue.component('WbEditor', WbEditor);
  vue.component('WbEditorContent', WbEditorContent);
};

export { WbEditor, WbEditorContent };
export default editor;
