var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col p-4"},[_c('div',{staticClass:"mb-4 flex items-center justify-between"},[_c('a-tooltip',{attrs:{"title":"Date of death","mouse-enter-delay":0.1}},[_c('div',{staticClass:"cursor-help font-medium"},[_vm._v(_vm._s(_vm.expirationDate))])]),_vm._v(" "),(_vm.expirationDuration)?_c('a-tooltip',{attrs:{"title":_vm.expirationDuration === 'Today'
          ? 'Expired today'
          : (_vm.expirationDuration + " since expiration"),"mouse-enter-delay":0.1}},[_c('wb-tag',{staticClass:"cursor-help",attrs:{"type":_vm.expirationTagType}},[_vm._v("\n        "+_vm._s(_vm.expirationDuration)+"\n      ")])],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mb-6 flex flex-wrap items-baseline justify-between gap-2"},[_c('div',{staticClass:"flex flex-1 flex-col gap-2 text-2xl"},[_c('span',{staticClass:"font-medium text-primary",staticStyle:{"word-break":"break-word"},attrs:{"title":_vm.summary.decedentName}},[_vm._v(_vm._s(_vm.summary.decedentName || 'Unidentified Decedent'))]),_vm._v(" "),('decedentAlias' in _vm.summary && _vm.summary.decedentAlias)?_c('div',{staticClass:"text-base",staticStyle:{"word-break":"break-word"}},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.labels.alias)+": ")]),_vm._v(_vm._s(_vm.summary.decedentAlias)+"\n      ")]):_vm._e(),_vm._v(" "),('decedentDateOfBirth' in _vm.summary && _vm.summary.decedentDateOfBirth)?_c('div',{staticClass:"text-base",staticStyle:{"word-break":"break-word"}},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.labels.dateOfBirth)+": ")]),_vm._v(_vm._s(_vm.formatDate(_vm.summary.decedentDateOfBirth))+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.isBodyboxSummary(_vm.summary))?_c('div',{staticClass:"flex flex-shrink flex-col gap-2"},[(
          'decedentMedicalRecordNumber' in _vm.summary &&
          _vm.summary.decedentMedicalRecordNumber
        )?_c('div',{staticClass:"break-all"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.labels.mrn)+": ")]),_vm._v(_vm._s(_vm.summary.decedentMedicalRecordNumber
            ? _vm.summary.decedentMedicalRecordNumber
            : 'Unknown')+"\n      ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('ul',{key:((_vm.summary.tags.computed.map(function (t) { return t.label; })) + "_" + (_vm.summary.tags.systemHolds.map(function (t) { return t.label; }).concat( _vm.summary.tags.system.map(function (t) { return t.label; }),
      _vm.summary.tags.user.map(function (t) { return t.label; }) ))),ref:"tagsList",staticClass:"-mt-2 mb-0"},[_vm._l((_vm.summary.tags.computed),function(tag,index){return _c('wb-tag',{key:((tag.label) + "_" + index),staticClass:"float-left mt-2 mr-2 last:mr-0",attrs:{"as":"li","type":"brand"}},[_vm._v("\n      "+_vm._s(tag.label)+"\n    ")])}),_vm._v(" "),_vm._l((_vm.summary.tags.systemHolds),function(tag,index){return _c('wb-tag',{key:((tag.label) + "_" + index),staticClass:"float-left mt-2 mr-2 flex items-center gap-1 last:mr-0",attrs:{"as":"li","type":tag.displayOptions ? 'dynamic' : 'danger',"color":_vm.getTagColor(tag)}},[_c('a-tooltip',{attrs:{"title":"This tag enforces a case hold.","mouse-enter-delay":0.1}},[_c('wb-icon',{staticClass:"cursor-help",attrs:{"name":"hand-palm"}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(tag.label))])],1)}),_vm._v(" "),_vm._l((_vm.summary.tags.system.concat( _vm.summary.tags.user)),function(tag,index){return _c('wb-tag',{key:((tag.label) + "_" + index),staticClass:"float-left mt-2 mr-2 last:mr-0",attrs:{"as":"li","type":"dynamic","color":_vm.getTagColor(tag)}},[_vm._v("\n      "+_vm._s(tag.label)+"\n    ")])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }