import { CaseAction } from '../case-action';
import {
  FormatDate,
  FormatDateTime,
  IsFormatDate,
  IsFormatDateTime,
} from '../../dates';
import { OriginLocationSummary } from './origin-location-summary';

export class OutboxCaseSummary {
  public readonly id!: string;

  public readonly isOpen!: boolean;

  public readonly decedentName!: string;

  public readonly decedentAlias?: string;

  public readonly decedentMedicalRecordNumber?: string;

  @IsFormatDate
  public readonly decedentDateOfBirth?: FormatDate;

  @IsFormatDate
  public readonly decedentExpirationDate!: FormatDate;

  @IsFormatDateTime
  public readonly lastCheckoutDate!: FormatDateTime;

  public readonly checkedOutBy!: string;

  readonly originLocation?: OriginLocationSummary;

  public readonly lastBodybox!: string;

  public readonly checkIn!: CaseAction;
}
