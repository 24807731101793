var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('calender-menu',_vm._b({ref:"menu",attrs:{"placement":"bottom-start"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var triggerId = ref.triggerId;
var menuId = ref.menuId;
var isOpen = ref.isOpen;
return [_c('date-time-picker',_vm._g(_vm._b({ref:"picker",staticClass:"w-full",attrs:{"id":triggerId,"value":_vm.value,"include-time":_vm.includeTime,"is24-hour":_vm.is24Hour,"type":_vm.type,"size":_vm.size,"readonly":_vm.readonly,"disabled":_vm.disabled,"aria-haspopup":"menu","aria-controls":menuId,"aria-expanded":isOpen},on:{"input":_vm.onPickerInput,"focus":_vm.onPickerFocus,"blur":_vm.onPickerBlur},scopedSlots:_vm._u([{key:"prefix",fn:function(scope){return [_vm._t("prefix",null,null,scope)]}},{key:"suffix",fn:function(scope){return [_vm._t("suffix",null,null,scope)]}}],null,true)},'date-time-picker',_vm.$attrs,false),_vm.$listeners))]}},{key:"default",fn:function(ref){
var show = ref.show;
var hide = ref.hide;
var shown = ref.shown;
var menuId = ref.menuId;
return [_vm._t("calendar:header",null,null,{ value: _vm.value, show: show, hide: hide, shown: shown, menuId: menuId }),_vm._v(" "),_c('v-calendar',_vm._b({ref:"calendarRef",attrs:{"value":_vm.calendarDate,"mode":'date',"is24hr":_vm.is24Hour,"min-date":_vm.minDate,"max-date":_vm.maxDate,"disabled-dates":_vm.disabledDates,"select-attribute":{
        highlight: { class: 'bg-brand-500', contentClass: 'text-white' },
        order: 2,
      },"attributes":[
        {
          key: 'today',
          dates: _vm.now(),
          highlight: { class: 'opacity-20', contentClass: 'text-[#1a202c]' },
          order: 1,
        } ].concat( (_vm.calendar ? _vm.calendar.attributes : []) ),"model-config":{ transition: 'slide-v' }},on:{"input":_vm.onCalendarInput},scopedSlots:_vm._u([{key:"day-popover",fn:function(ref){
        var day = ref.day;
        var format = ref.format;
        var masks = ref.masks;
        var updateLayout = ref.updateLayout;
        var hidePopover = ref.hide;
        var attributes = ref.attributes;
return [_vm._t("calendar:day:popover",null,{"day":day,"format":format,"masks":masks,"updateLayout":updateLayout,"hide":hidePopover,"attributes":attributes})]}}],null,true)},'v-calendar',_vm.calendar,false)),_vm._v(" "),_c('div',{staticClass:"border-t border-gray-300 pt-2"},[(_vm.$scopedSlots['calendar:footer'])?_vm._t("calendar:footer",null,null,{ value: _vm.value, show: show, hide: hide, shown: shown, menuId: menuId }):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-1 flex items-center gap-2"},[(_vm.includeTime)?_c('a-tooltip',{attrs:{"placement":'top',"get-popup-container":function () { return _vm.getTooltipContainer(menuId); }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(_vm.getTime('now')))])]},proxy:true}],null,true)},[_vm._v(" "),_c('button',{staticClass:"flex-grow rounded font-medium text-brand-500 transition-colors duration-75 ease-linear hover:bg-brand-100",on:{"click":function($event){return _vm.setDateTimeNow()}}},[_vm._v("\n            Now\n          ")])]):_vm._e(),_vm._v(" "),_c('a-tooltip',{attrs:{"placement":'top',"get-popup-container":function () { return _vm.getTooltipContainer(menuId); }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(_vm.getTime('today')))])]},proxy:true}],null,true)},[_vm._v(" "),_c('button',{staticClass:"flex-grow rounded font-medium text-brand-500 transition-colors duration-75 ease-linear hover:bg-brand-100",on:{"click":function($event){return _vm.setDateTimeToday()}}},[_vm._v("\n            Today\n          ")])])],1)],2)]}}],null,true)},'calender-menu',_vm.dropdown,false))}
var staticRenderFns = []

export { render, staticRenderFns }