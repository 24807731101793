





































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useStoreModule } from '~/app/composables/use-store-module';
import { useAuthProvider } from '~/app/core/auth';
import {
  useAbility,
  AppAbilitySubject,
  BodyboxAbilityAction,
} from '~/app/core/casl';
import { ITenantState, moduleName as tenantStoreName } from '~/store/tenant';
import { useStore as useBodyboxStore } from '~mod:bodybox/composables/use-store';
import {
  TabBar,
  TabBarItem,
  TabBarMenu,
} from '~mod:layout/components/molecules/tab-bar';
import { SettingsRoute } from '~mod:settings/routes';

export default defineComponent({
  name: 'TheBottomBar',
  components: {
    TabBar,
    TabBarItem,
    TabBarMenu,
  },
  setup() {
    const tenantModule = useStoreModule<ITenantState>(tenantStoreName);
    const tenantName = computed(() => tenantModule.state.tenant?.name);

    const { state } = useBodyboxStore();
    const bodyboxes = computed(() => state.bodyboxes || []);

    const auth = useAuthProvider();
    const logout = () => auth.logoutAsync();

    // #region access control

    const ability = useAbility();

    const canViewBodyboxes = computed(() =>
      ability.value.can(BodyboxAbilityAction.read, AppAbilitySubject.bodybox)
    );

    // #endregion

    return {
      tenantName,
      bodyboxes,
      canViewBodyboxes,
      SettingsRoute,
      logout,
    };
  },
});
