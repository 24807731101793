import { setInteractionMode } from 'vee-validate';

import './common';
import './custom';
import './date';
import './phone';
import './ssn';
import { DateOnly, DateTimeInfo } from '~/app/models';

export type RuleProp =
  | 'custom'
  | 'date'
  | 'email'
  | 'is'
  | 'is_future_date'
  | 'is_future_or_same_date'
  | 'is_past_date'
  | 'is_past_or_same_date'
  | 'length'
  | 'max'
  | 'max_value'
  | 'min'
  | 'min_value'
  | 'oneOf'
  | 'phone'
  | 'required'
  | 'required_if_empty'
  | 'required_if_any_empty'
  | 'required_if_all_empty'
  | 'required_enum'
  | 'type'
  | 'ssn';

type Primitive =
  | string
  | number
  | boolean
  | Date
  | DateOnly
  | DateTimeInfo
  | symbol
  | null
  | undefined;

/**
 * Converts an object type to a string type with dot notation.
 * To access nested properties in an object use dot notation ex. 'objProp.nestedProp'.
 * To access array elements use dot notation with array postfix ex. 'arrProp[].nestedProp'.
 */
export type DotNotation<
  TObj,
  Prefix extends string = '',
  ArrayPostfix extends string = '[]'
> = {
  [Key in keyof TObj]: TObj[Key] extends Primitive | Function
    ? `${Prefix}${Key & string}`
    : TObj[Key] extends Array<infer U>
    ? DotNotation<U, `${Prefix}${Key & string}${ArrayPostfix}.`>
    : DotNotation<TObj[Key], `${Prefix}${Key & string}.`>;
}[keyof TObj];

export type RuleObject = { [key in RuleProp]?: any };
export type Rule = string | RuleObject;

// @ts-ignore
export type Rules<
  TModel extends Record<string, any> = Record<string, Primitive>
  // @ts-ignore
> = Record<DotNotation<TModel>, RuleObject>;

// export type Rules<TModel extends object = any> = Record<
//   keyof TModel,
//   RuleObject
// >;

setInteractionMode('blur', (_context) => ({ on: ['blur'] }));
