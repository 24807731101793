import {
  AutopsyServiceCollection,
  Case,
  CaseHold,
  DateTimeInfo,
  DeathCertificate,
  DonationProcurementCollection,
  FuneralHome,
  HouseAutopsy,
  InfectiousDisease,
  MedicalExaminer,
  MedicolegalInvestigationCollection,
  NextOfKinRecord,
  NoteRecord,
  OrganDonationRecord,
  OriginLocation,
  RequirementStatus,
  VendorAutopsy,
} from '~/app/models';

export class CreateCaseRequest {
  public readonly typeId?: string;

  public readonly reportedBy?: string;

  public readonly storageLocationId?: string;

  public readonly holds?: Readonly<CaseHold>;

  public readonly deathCertificate?: DeathCertificate;

  public readonly alias?: string;

  public readonly firstName?: string;

  public readonly middleName?: string;

  public readonly lastName?: string;

  public readonly genderId?: string;

  public readonly dateOfBirth?: DateTimeInfo;

  public readonly dateOfDeath?: DateTimeInfo;

  public readonly medicalRecordNumber?: string;

  public readonly socialSecurityNumber?: string;

  public readonly financialIdentificationNumber?: string;

  public readonly weight?: number;

  public readonly raceId?: string;

  public readonly ethnicityId?: string;

  public readonly maritalStatusId?: string;

  public readonly countyId?: string;

  public readonly systemTagIds?: string[];

  public readonly userTags?: string[];

  public readonly personalProperty?: Readonly<{
    [locationId: string]: string[];
  }>;

  public readonly autopsyRequirementStatus?: RequirementStatus;

  public readonly inHouseAutopsies?: Array<Readonly<HouseAutopsy>>;

  public readonly vendorAutopsies?: Array<Readonly<VendorAutopsy>>;

  public autopsies?: AutopsyServiceCollection;

  public readonly organDonationRequirementStatus?: RequirementStatus;

  public readonly organDonations?: Array<Readonly<OrganDonationRecord>>;

  public donationProcurements?: DonationProcurementCollection;

  public readonly funeralHome?: Readonly<FuneralHome>;

  public readonly originLocation?: Readonly<OriginLocation>;

  public readonly medicalExaminer?: Readonly<MedicalExaminer>;

  public readonly medicolegalInvestigations?: MedicolegalInvestigationCollection;

  public readonly infectiousDisease?: Readonly<InfectiousDisease>;

  public readonly nextOfKin?: Array<Readonly<NextOfKinRecord>>;

  public readonly notes?: Pick<NoteRecord, 'content' | 'tagIds'>[];

  public readonly documentIds?: string[];

  constructor(details: Case) {
    this.typeId = details.typeId;
    this.reportedBy = details.reportedBy;
    this.storageLocationId = details.storageLocationId;
    this.holds = details.holds;
    this.deathCertificate = details.deathCertificate;
    this.alias = details.decedent?.alias;
    this.firstName = details.decedent?.firstName;
    this.middleName = details.decedent?.middleName;
    this.lastName = details.decedent?.lastName;
    this.genderId = details.decedent?.genderId;
    this.dateOfBirth = details.decedent?.dateOfBirth
      ? new DateTimeInfo(details.decedent.dateOfBirth)
      : undefined;
    this.dateOfDeath = details.decedent?.dateOfDeath
      ? new DateTimeInfo(details.decedent.dateOfDeath)
      : undefined;
    this.medicalRecordNumber = details.decedent?.medicalRecordNumber;
    this.socialSecurityNumber = details.decedent?.socialSecurityNumber;
    this.financialIdentificationNumber = details.financialIdentificationNumber;
    this.weight = details.decedent?.weight;
    this.raceId = details.decedent?.raceId;
    this.ethnicityId = details.decedent?.ethnicityId;
    this.maritalStatusId = details.decedent?.maritalStatusId;
    this.countyId = details.decedent?.countyId;
    this.systemTagIds = details.tags?.systemTagIds;
    this.userTags = details.tags?.userTags;
    this.personalProperty = details.property?.locations;
    this.autopsyRequirementStatus = details.autopsies?.requirementStatus;
    this.inHouseAutopsies = details.autopsies?.inHouse;
    this.vendorAutopsies = details.autopsies?.vendor;
    this.autopsies = details.autopsyServices;
    this.organDonationRequirementStatus =
      details.organDonations?.requirementStatus;
    this.organDonations = details.organDonations?.records;
    this.donationProcurements = details.donationProcurements;
    this.funeralHome = details.funeralHome;
    this.originLocation = details.originLocation;
    this.medicalExaminer = details.medicalExaminer;
    this.medicolegalInvestigations = details.medicolegalInvestigations;
    this.infectiousDisease = details.infectiousDisease;
    this.nextOfKin = details.nextOfKin?.records;
    this.notes = details.notes?.records.map(({ content, tagIds }) => ({
      content,
      tagIds,
    }));
    this.documentIds = details.documents?.records.map((c) => c.id!);
  }
}
