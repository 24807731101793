import { MutationTree } from 'vuex';
import { ICaseDetailsState } from './state';
import { Case } from '~/app/models';

export enum Mutation {
  init = 'INITIALIZE',
  reset = 'RESET',
  setCase = 'SET_CASE',
  setCaseId = 'SET_CASE_ID',
  verifyIdentity = 'VERIFY_IDENTITY',
}

export const mutations: MutationTree<ICaseDetailsState> = {
  [Mutation.init](state, caseDetails: Case) {
    state.case = caseDetails || new Case();
    state.caseId = state.case.id ?? null;
    state.identityVerified = false;
  },
  [Mutation.reset](state) {
    state.case = null;
    state.caseId = null;
    state.identityVerified = false;
  },
  [Mutation.setCase](state, caseDetails: Case) {
    if (!caseDetails) throw new Error('case must have a value.');
    state.case = caseDetails;
    state.caseId = caseDetails.id ?? null;
  },
  [Mutation.setCaseId](state, id: string) {
    if (!id) throw new Error('Case ID must have a value');
    state.caseId = id;
  },
  [Mutation.verifyIdentity](state, verified = true) {
    state.identityVerified = verified;
  },
};
