export const EventCategory = {
  UserAccess: 'User Access',
  Integration: 'Integration',
  CaseGeneral: 'General',
  CaseNote: 'Case Notes',
  CaseHold: 'Case Hold',
  DecedentTransport: 'Transport',
  DecedentNextOfKin: 'Next Of Kin',
  DecedentAutopsy: 'Autopsy',
  DecedentMedicolegalInvestigation: 'Investigation',
  DecedentDonationProcurement: 'Donation Procurements',
  DecedentFuneralHome: 'Funeral Home',
} as const;

// eslint-disable-next-line no-redeclare
export type EventCategory = typeof EventCategory[keyof typeof EventCategory];
