






























































































































































































































































































































































































import {
  ref,
  computed,
  defineComponent,
  toRaw,
  PropType,
} from '@nuxtjs/composition-api';
import { useIntersectionObserver, useResizeObserver } from '@vueuse/core';
import { defu } from 'defu';
import { tableConfig } from './config';
import type {
  TableColumn,
  SortState,
  TableFooterCell,
  TableFooterRow,
} from './types';
import { orderBy } from '~/app/utils/sorting';

function defaultComparator<T>(a: T, z: T): boolean {
  return a === z;
}

function capitalizeFirstCharacter(str: string) {
  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  return firstChar + restOfString;
}

// function isNil(value: any) {
//   return value === null || value === undefined;
// }

export default defineComponent({
  name: 'Table',
  props: {
    /** TODO: Support row selection */
    selected: {
      type: Array as PropType<any[]>,
      default: null,
    },
    by: {
      type: [String, Function] as PropType<string | typeof defaultComparator>,
      default: () => defaultComparator,
    },
    rows: {
      type: Array as PropType<{ [key: string]: any; click?: Function }[]>,
      default: () => [],
    },
    columns: {
      type: Array as PropType<TableColumn[]>,
      default: null,
    },
    columnAttribute: {
      type: String,
      default: 'label',
    },
    footer: {
      type: Array as PropType<TableFooterRow[]>,
      default: null,
    },
    sort: {
      type: Object as PropType<SortState>,
      default: () => ({}),
    },
    sortButton: {
      type: Object as PropType<unknown>,
      default: () => tableConfig.default.sortButton,
    },
    sortAscIcon: {
      type: String,
      default: () => tableConfig.default.sortAscIcon,
    },
    sortDescIcon: {
      type: String,
      default: () => tableConfig.default.sortDescIcon,
    },
    expandButton: {
      type: Object as PropType<unknown>,
      default: () => tableConfig.default.expandButton,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: true,
    },
    loadingState: {
      type: Object as PropType<{ icon: string; label: string }>,
      default: () => tableConfig.default.loadingState,
    },
    emptyState: {
      type: Object as PropType<{ icon: string; label: string }>,
      default: () => tableConfig.default.emptyState,
    },
    config: {
      type: Object as PropType<Partial<typeof tableConfig>>,
      default: () => tableConfig,
    },
    /** Represents a sticky positioned header where the table scrolls with the page */
    sticky: {
      type: [Boolean, String],
      default: false,
    },
    /** Represents a scrollable (typically fixed height) table where the header remains fixed to the top and the overflow body content scrolls */
    scrollable: {
      type: Boolean,
      default: false,
    },
    rowClass: {
      type: [String, Function] as PropType<
        string | ((row: any, index: number) => string)
      >,
      required: false,
      default: undefined,
    },
  },
  emits: ['update:selected', 'row:click', 'row:dblclick', 'sort'],
  setup(props, { emit, attrs, slots }) {
    const ui = computed<typeof tableConfig>(() =>
      defu({}, props.config, tableConfig)
    );

    const isSticky = computed(
      () =>
        ['string', 'boolean'].includes(typeof props.sticky) &&
        props.sticky !== false
    );

    const hasExpand = computed(() => !!slots.expand);

    function getFooterCellSchema<
      TData extends Record<string, any> = Record<string, any>
    >(cells: TableFooterCell<Record<string, any>>[], data?: TData) {
      const dataObj = (data ?? {}) as TData;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { click, ...otherProps } = dataObj;
      return (cells ??
        Object.keys(otherProps).map((key) => ({
          key,
          label: capitalizeFirstCharacter(key),
          sortable: false,
        }))) as TableFooterCell<Record<string, any>>[];
    }

    const columnsSchema = computed<TableColumn<Record<string, any>>[]>(() => {
      const row0Props: ArrayItemType<typeof props.rows> = props.rows[0] ?? {};
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { click, ...otherProps } = row0Props;
      return (props.columns ??
        Object.keys(otherProps).map((key) => ({
          key,
          label: capitalizeFirstCharacter(key),
          sortable: false,
        }))) as TableColumn<Record<string, any>>[];
    });

    const sortState = ref<SortState>(
      defu({}, props.sort, {
        column: null,
        direction: 'asc',
        mode: 'memory',
      }) as SortState
    );

    const rowData = computed(() => {
      if (!sortState.value?.column) {
        return props.rows;
      }

      const { column, direction, mode } = sortState.value;
      const col = columnsSchema.value.find(
        (c) => c.key.toString() === column?.toString()
      );

      if (mode === 'custom') return props.rows;

      const sortFn =
        typeof col?.sortable === 'boolean' ? undefined : col?.sortable?.sort;
      if (sortFn) {
        return [...props.rows].sort((a, z) => sortFn(a, z, column, direction));
      }

      // Normalize/Resolve column values for sorting
      const sortColumnKey = `__${column}_SORT__`;
      const data = props.rows.map((row) => {
        const value = resolveColumnValue(row, col!);
        return { ...row, [sortColumnKey]: value };
      });

      return orderBy(data, sortColumnKey, {
        direction: direction as typeof props.sort.direction,
      });
    });

    const selectedState = computed<any>({
      get() {
        return props.selected;
      },
      set(value) {
        emit('update:selected', value);
      },
    });

    const indeterminate = computed(
      () =>
        selectedState.value &&
        selectedState.value.length > 0 &&
        selectedState.value.length < props.rows.length
    );

    const emptyStateSchema = computed(() => ({
      ...ui.value.default!.emptyState,
      ...props.emptyState,
    }));

    /**
     * Row data can be represented as a raw value or nested within a cell configuration.
     * - Cell Configuration - `{ value: any; class?: string }
     * - Raw Value - `any`
     */
    function resolveColumnValue(row: any, column: TableColumn) {
      const valueOrCellConfig = row[column.key];
      const resolvedValue =
        valueOrCellConfig &&
        typeof valueOrCellConfig === 'object' &&
        'value' in valueOrCellConfig
          ? valueOrCellConfig.value
          : valueOrCellConfig;

      return column.transform // && !isNil(resolvedValue)
        ? column.transform(resolvedValue, row)
        : resolvedValue;
    }

    function resolveCellClass(row: any, column: TableColumn) {
      const valueOrCellConfig = row[column.key];
      if ([null, undefined].includes(valueOrCellConfig)) return null;
      return typeof valueOrCellConfig === 'object' &&
        'class' in valueOrCellConfig
        ? valueOrCellConfig.class
        : null;
    }

    function compare(a: any, z: any) {
      if (typeof props.by === 'string') {
        const property = (props.by as unknown) as any;
        return a?.[property] === z?.[property];
      }
      return props.by(a, z);
    }

    function isSelected(row: any) {
      if (!props.selected) {
        return false;
      }

      return selectedState.value.some((item: any) =>
        compare(toRaw(item), toRaw(row))
      );
    }

    function getSortState(column: TableColumn): SortState {
      if (!column.sortable) {
        return {
          column: null,
          direction: 'asc',
          mode: 'memory',
        };
      }

      if (typeof column.sortable === 'boolean') {
        return {
          column: column.key,
          direction: column.direction || 'asc',
          mode: props.sort?.mode === 'custom' ? 'custom' : 'memory',
        };
      }

      return {
        column: column.key,
        direction: column.direction || 'asc',
        mode:
          column.sortable?.custom ?? props.sort?.mode === 'custom'
            ? 'custom'
            : 'memory',
      };
    }

    function onSort(column: TableColumn) {
      const state = getSortState(column);
      if (sortState.value.column === column.key) {
        const direction =
          !column.direction || column.direction === 'asc' ? 'desc' : 'asc';

        if (sortState.value.direction === direction) {
          // Reset sort state
          sortState.value = defu({}, props.sort, {
            ...state,
            column: null,
          } as SortState);
        } else {
          sortState.value.direction =
            sortState.value.direction === 'asc' ? 'desc' : 'asc';
        }
      } else {
        sortState.value = state;
      }

      emit('sort', sortState.value, column.key, column);
    }

    const openedRows = ref<number[]>([]);
    function toggleOpened(index: number) {
      // TODO: apply column sticking"
      if (openedRows.value.includes(index)) {
        openedRows.value = openedRows.value.filter((i) => i !== index);
      } else {
        openedRows.value.push(index);
      }
    }

    function onRowDoubleClick(row: any) {
      // TODO: Prevent event if trigger was expand button
      emit('row:dblclick', row);
    }

    function onSelect(row: any) {
      emit('row:click', row);

      if (!attrs.onSelect) {
        return;
      }

      // @ts-ignore
      attrs.onSelect(row);
    }

    function selectAllRows() {
      props.rows.forEach((row) => {
        // If the row is already selected, don't select it again
        if (
          selectedState.value.some((item: any) =>
            compare(toRaw(item), toRaw(row))
          )
        ) {
          return;
        }

        onSelect(row);
      });
    }

    function onChange(event: any) {
      if (event.target.checked) {
        selectAllRows();
      } else {
        selectedState.value = [];
      }
    }

    // #region Sticky Columns

    const thead = ref<HTMLElement>();
    useIntersectionObserver(
      thead,
      ([e]) => {
        const thead = e.target;
        thead
          .querySelectorAll('th')
          .forEach((th) =>
            ui.value.th.stuck
              .split(' ')
              .forEach((cl) => th.classList.toggle(cl, e.intersectionRatio < 1))
          );
      },
      { threshold: [1] }
    );

    const tbody = ref<HTMLBodyElement>();
    type StickyDirection = 'left' | 'right';
    type ColRef = {
      el: HTMLTableCellElement;
      key: string;
      order: number;
      direction: StickyDirection;
    };

    useResizeObserver(thead, ([e]) => {
      const thead = e.target;
      const headersCells = thead.querySelectorAll(
        'tr > th'
        // eslint-disable-next-line no-undef
      ) as NodeListOf<HTMLTableCellElement>;

      const stickyHeaders = Array.from(headersCells)
        .filter((th) => th.dataset.colSticky)
        .map<ColRef>((th) => {
          const dir =
            th.dataset.colSticky === 'true' ? 'left' : th.dataset.colSticky!;
          return {
            el: th,
            key: th.dataset.colKey!,
            order: th.dataset.colOrder ? +th.dataset.colOrder : 0,
            direction: dir as StickyDirection,
          };
        })
        .sort((a, z) => a.order - z.order);

      if (!stickyHeaders.length) return;

      const colWidths = stickyHeaders.reduce((acc, { el, key }) => {
        const offset = el.getBoundingClientRect().width;
        acc[key] = offset;
        return acc;
      }, {} as Record<string, number>);

      if (!tbody.value) return;

      const bodyCells = tbody.value.querySelectorAll(
        'tr > td'
        // eslint-disable-next-line no-undef
      ) as NodeListOf<HTMLTableCellElement>;

      const stickyCells = Array.from(bodyCells)
        .filter((td) => td.dataset.colSticky)
        .map<ColRef>((th) => {
          const dir =
            th.dataset.colSticky === 'true' ? 'left' : th.dataset.colSticky!;
          return {
            el: th,
            key: th.dataset.colKey!,
            order: th.dataset.colOrder ? +th.dataset.colOrder : 0,
            direction: dir as StickyDirection,
          };
        })
        .sort((a, z) => a.order - z.order);

      function getOffset(col: ColRef, cols: ColRef[]) {
        return cols
          .filter((c) => c.order < col.order)
          .sort((a, z) => a.order - z.order)
          .reduce((acc, { key }) => acc + colWidths[key], 0);
      }

      // Set offset for each sticky column type
      const stickyHeaderGroup = {
        left: stickyHeaders.filter((col) => col.direction === 'left'),
        right: stickyHeaders.filter((col) => col.direction === 'right'),
      };

      stickyHeaderGroup.left.forEach((col) => {
        const offset = getOffset(col, stickyHeaderGroup.left);
        const cells = stickyCells.filter((c) => c.key === col.key);

        col.el.style.left = `${offset}px`;
        cells.forEach((c) => (c.el.style.left = `${offset}px`));
      });

      stickyHeaderGroup.right.forEach((col) => {
        const offset = getOffset(col, stickyHeaderGroup.right);
        const cells = stickyCells.filter((c) => c.key === col.key);

        col.el.style.right = `${offset}px`;
        cells.forEach((c) => (c.el.style.right = `${offset}px`));
      });
    });

    // #endregion

    function getRowClass(row: any, index: number) {
      if (!props.rowClass) {
        return null;
      }

      if (typeof props.rowClass === 'string') {
        return props.rowClass;
      }

      return props.rowClass(row, index);
    }

    return {
      ui,
      isSticky,
      hasExpand,
      sortState,
      columnsSchema,
      getFooterCellSchema,
      rowData,
      selectedState,
      indeterminate,
      emptyStateSchema,
      isSelected,
      resolveColumnValue,
      resolveCellClass,
      openedRows,
      toggleOpened,
      onSort,
      onRowDoubleClick,
      onSelect,
      onChange,
      getRowClass,

      thead,
      tbody,
    };
  },
});
