

































/* eslint-disable vue/require-prop-types */
import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@nuxtjs/composition-api';
import { BubbleMenu, Editor } from '@tiptap/vue-2';
import { Instance } from 'tippy.js';
import WbEditorContent, { Props as ContentProps } from './editor-content.vue';
import WbEditorToolbar from './editor-toolbar.vue';

export default defineComponent({
  name: 'WbEditor',
  components: {
    BubbleMenu,
    WbEditorContent,
    WbEditorToolbar,
  },
  props: {
    ...ContentProps,
    readonly: {
      ...ContentProps.readonly,
      default: false,
    },
  },
  setup() {
    const content = ref<InstanceType<typeof WbEditorContent>>();
    const editor = ref<Editor>();

    const isEmpty = computed(() => {
      return content.value?.isEmpty ?? true;
    });

    onMounted(() => {
      editor.value = content.value?.editor;
    });

    function onMenuCreate(instance: Instance) {
      instance.popper.classList.add('wb-editor-bubble-menu--popper');
    }

    function focusInput() {
      content.value?.focus();
    }

    return {
      content,
      editor,
      onMenuCreate,
      focusInput,
      isEmpty,
      body: document.body,
    };
  },
});
