import { Transform, Type } from 'class-transformer';
import { DisplayTag } from '../../tag';

const transformLegacyTags = (value: unknown) =>
  Array.isArray(value)
    ? value.map((v) =>
        typeof v === 'string'
          ? new DisplayTag({ label: v as string })
          : new DisplayTag({
              label: v?.label,
              displayOptions: v?.displayOptions,
            })
      )
    : [];

export class CaseSummaryTags {
  @Type(() => DisplayTag)
  public readonly system!: DisplayTag[];

  @Type(() => DisplayTag)
  public readonly systemHolds!: DisplayTag[];

  @Transform(({ value }) => transformLegacyTags(value))
  @Type(() => DisplayTag)
  public readonly user!: DisplayTag[];

  @Transform(({ value }) => transformLegacyTags(value))
  @Type(() => DisplayTag)
  public readonly computed!: DisplayTag[];

  public get empty() {
    return (
      !this.system.length &&
      !this.systemHolds.length &&
      !this.user.length &&
      !this.computed.length
    );
  }
}
