export enum AppAbilitySubject {
  case = 'case',
  bodybox = 'bodybox',
  dictionary = 'dictionary',
  user = 'user',
  report = 'report',
}

// #region Actions

export enum CaseAbilityAction {
  create = 'case:create',
  read = 'case:read',
  update = 'case:update',
  delete = 'case:delete',
  checkIn = 'case:checkin',
  checkout = 'case:checkout',
  transferInternal = 'case:internal-transfer',
  transferExternal = 'case:external-transfer',
  open = 'case:open',
  close = 'case:close',
  touch = 'case:touch',
}

export enum BodyboxAbilityAction {
  create = 'bodybox:create',
  read = 'bodybox:read',
  update = 'bodybox:update',
  delete = 'bodybox:delete',
}

export enum DictionaryAbilityAction {
  create = 'dictionary:create',
  read = 'dictionary:read',
  update = 'dictionary:update',
  delete = 'dictionary:delete',
}

export enum UserAbilityAction {
  create = 'user:create',
  read = 'user:read',
  update = 'user:update',
  delete = 'user:delete',
}

export enum ReportAbilityAction {
  create = 'report:create',
}

// #endregion
