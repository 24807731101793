import { Type } from 'class-transformer';
import { DateOnly } from './date-only';
import { parseISODateOnly, parseISODateTime } from './utils';

export interface DateTimeInfoOptions {
  /** Indicates if the date should always be serialized as a DateOnly or Date. */
  serialize?: 'date' | 'dateTime';
}

function isDateTimeInfoStructure(obj: any): obj is DateTimeInfo {
  return (
    obj &&
    typeof obj === 'object' &&
    ('date' in obj || 'time' in obj) &&
    (typeof obj.date === 'string' || obj.date instanceof DateOnly) &&
    (typeof obj.time === 'string' ||
      obj.time instanceof Date ||
      obj.time === undefined)
  );
}

export class DateTimeInfo {
  private readonly options?: DateTimeInfoOptions;

  @Type(() => DateOnly)
  public readonly date!: DateOnly;

  @Type(() => Date)
  public readonly time?: Date;

  constructor(
    args: DateOnly | Date | DateTimeInfo | string,
    options?: DateTimeInfoOptions
  ) {
    if (!args) return;

    this.options = options;

    if (typeof args === 'string') {
      const date = parseISODateOnly(args);
      if (date) {
        this.date = new DateOnly(date);
        this.time = undefined;
        return;
      }

      const dateTime = parseISODateTime(args);
      if (dateTime) {
        this.date = new DateOnly(dateTime);
        this.time = dateTime;
        return;
      }

      throw new Error('Invalid ISO 8601 date string');
    }

    if (args instanceof DateTimeInfo || isDateTimeInfoStructure(args)) {
      this.date =
        args.date instanceof DateOnly ? args.date : new DateOnly(args.date);
      this.time = args.time
        ? args.time instanceof Date
          ? args.time
          : new Date(args.time)
        : undefined;
      return;
    }

    if (args instanceof DateOnly) {
      this.date = args;
      return;
    }

    this.date = new DateOnly(args);
    this.time = args;
  }

  public get value(): Date | DateOnly {
    return this.time || this.date;
  }

  public toString() {
    return this.toJSON();
  }

  public toDate(): Date {
    return this.time || this.date.toDate();
  }

  public toJSON(): string {
    if (this.options?.serialize)
      return this.options?.serialize === 'date'
        ? this.date.toJSON()
        : this.toDate().toISOString();

    if (this.time) return this.time.toISOString();
    return this.date.toISOString();
  }
}
