import { Transform, Type } from 'class-transformer';
import { IIdentifiable } from '../interfaces/identifiable';
import { AutopsyCollection } from './autopsy-collection';
import { NotesCollection } from './notes-collection';
import { FuneralHome } from './funeral-home';
import { OrganDonationCollection } from './organ-donation-collection';
import { DecedentInfo } from './decedent-info';
import { BelongingsCollection } from './personal-belongings-collection';
import { TagsCollection } from './tags-collection';
import { TransportHistory } from './transport-history';
import { DocumentCollection } from './document-collection';
import { CaseAction } from './case-action';
import { MedicalExaminer } from './medical-examiner';
import { InfectiousDisease } from './infectious-disease';
import {
  ExternalOriginLocation,
  InternalOriginLocation,
  OriginLocation,
  OriginLocationType,
} from './origin-location';
import { CaseHold } from './hold';
import { CaseNotice } from './notice';
import { NextOfKinCollection } from './next-of-kin-collection';
import { DeathCertificate } from './death-certificate';
import {
  AutopsyServiceCollection,
  DonationProcurementCollection,
  MedicolegalInvestigationCollection,
} from './services';

export class Case implements Partial<IIdentifiable<string>> {
  public id?: string;

  public isOpen!: boolean;

  public typeId?: string;

  public reportedBy?: string;

  public touches?: number;

  public storageLocationId?: string;

  public bodyboxId?: string;

  public financialIdentificationNumber?: string;

  @Type(() => CaseNotice)
  public notices?: CaseNotice[];

  @Type(() => DecedentInfo)
  public decedent?: DecedentInfo;

  @Type(() => CaseHold)
  public holds?: CaseHold;

  @Type(() => DeathCertificate)
  public deathCertificate?: DeathCertificate;

  @Type(() => AutopsyCollection)
  public autopsies?: AutopsyCollection;

  @Type(() => AutopsyServiceCollection)
  public autopsyServices?: AutopsyServiceCollection;

  @Type(() => TagsCollection)
  public tags?: TagsCollection;

  @Type(() => BelongingsCollection)
  public property?: BelongingsCollection;

  @Type(() => OrganDonationCollection)
  public organDonations?: OrganDonationCollection;

  @Type(() => DonationProcurementCollection)
  public donationProcurements?: DonationProcurementCollection;

  @Type(() => OriginLocation)
  @Transform(({ value }) => {
    if (!value || !('type' in value)) return value;
    if (value.type === OriginLocationType.Internal)
      return new InternalOriginLocation(value);
    if (value.type === OriginLocationType.External)
      return new ExternalOriginLocation(value);
    throw new Error('Unable to construct OriginLocation - type is invalid.');
  })
  public originLocation?: OriginLocation;

  @Type(() => MedicalExaminer)
  public medicalExaminer?: MedicalExaminer;

  @Type(() => MedicolegalInvestigationCollection)
  public medicolegalInvestigations?: MedicolegalInvestigationCollection;

  @Type(() => FuneralHome)
  public funeralHome?: FuneralHome;

  @Type(() => InfectiousDisease)
  public infectiousDisease?: InfectiousDisease;

  @Type(() => NextOfKinCollection)
  public nextOfKin?: NextOfKinCollection;

  @Type(() => NotesCollection)
  public notes?: NotesCollection;

  @Type(() => TransportHistory)
  public transportHistory?: TransportHistory;

  @Type(() => DocumentCollection)
  public documents?: DocumentCollection;

  @Type(() => CaseAction)
  public readonly checkIn?: Readonly<CaseAction>;

  @Type(() => CaseAction)
  public readonly checkOut?: Readonly<CaseAction>;

  constructor(args?: Partial<Case>) {
    this.id = args?.id;
    this.isOpen = args?.isOpen || true;
    this.typeId = args?.typeId;
    this.reportedBy = args?.reportedBy;
    this.touches = args?.touches;
    this.storageLocationId = args?.storageLocationId;
    this.bodyboxId = args?.bodyboxId;
    this.notices = args?.notices;
    this.decedent = args?.decedent;
    this.holds = args?.holds;
    this.deathCertificate = args?.deathCertificate;
    this.autopsies = args?.autopsies;
    this.autopsyServices = args?.autopsyServices;
    this.tags = args?.tags;
    this.property = args?.property;
    this.organDonations = args?.organDonations;
    this.donationProcurements = args?.donationProcurements;
    this.originLocation = args?.originLocation;
    this.medicalExaminer = args?.medicalExaminer;
    this.medicolegalInvestigations = args?.medicolegalInvestigations;
    this.funeralHome = args?.funeralHome;
    this.infectiousDisease = args?.infectiousDisease;
    this.nextOfKin = args?.nextOfKin;
    this.notes = args?.notes;
    this.transportHistory = args?.transportHistory;
    this.documents = args?.documents;
  }

  public addNotices(notices?: CaseNotice[]) {
    if (this.notices) throw new Error('Notices collection already exists.');

    this.notices = notices || [];

    return this;
  }

  public addDecedentInfo(info?: DecedentInfo) {
    if (this.decedent) throw new Error('Decedent info already exists.');

    this.decedent = info || new DecedentInfo();

    return this;
  }

  public addHolds(hold?: CaseHold) {
    if (this.holds) throw new Error('A hold already exists.');

    this.holds = hold || new CaseHold();

    return this;
  }

  public addDeathCertificate(deathCertificate?: DeathCertificate) {
    if (this.deathCertificate)
      throw new Error('A death certificate already exists.');

    this.deathCertificate = deathCertificate || new DeathCertificate();

    return this;
  }

  public addAutopsyCollection(collection?: AutopsyCollection) {
    if (this.autopsies)
      throw new Error('An autopsy collection already exists.');

    this.autopsies = collection || new AutopsyCollection();

    return this;
  }

  public addAutopsyServiceCollection(collection?: AutopsyServiceCollection) {
    if (this.autopsyServices)
      throw new Error('An autopsy service collection already exists.');

    this.autopsyServices = collection || new AutopsyServiceCollection();

    return this;
  }

  public addTagsCollection(collection?: TagsCollection) {
    if (this.tags) throw new Error('A tags collection already exists.');

    this.tags = collection || new TagsCollection();

    return this;
  }

  public addBelongingsCollection(collection?: BelongingsCollection) {
    if (this.property)
      throw new Error('A personal belongings collection already exists.');

    this.property = collection || new BelongingsCollection();

    return this;
  }

  public addOrganDonationCollection(collection?: OrganDonationCollection) {
    if (this.organDonations)
      throw new Error('An organ donation collection already exists.');

    this.organDonations = collection || new OrganDonationCollection();

    return this;
  }

  public addDonationProcurementCollection(
    collection?: DonationProcurementCollection
  ) {
    if (this.donationProcurements)
      throw new Error('A donation procurement collection already exists.');

    this.donationProcurements =
      collection || new DonationProcurementCollection();

    return this;
  }

  public addOriginLocation(location?: OriginLocation) {
    if (this.originLocation)
      throw new Error('An origin location already exists.');

    this.originLocation = location || new InternalOriginLocation();

    return this;
  }

  public addMedicalExaminerUnit(medicalExaminer?: MedicalExaminer) {
    if (this.medicalExaminer)
      throw new Error('A medical examiner already exists.');

    this.medicalExaminer = medicalExaminer || new MedicalExaminer();

    return this;
  }

  public addMedicolegalInvestigationCollection(
    collection?: MedicolegalInvestigationCollection
  ) {
    if (this.medicolegalInvestigations)
      throw new Error('A medicolegal investigation collection already exists.');

    this.medicolegalInvestigations =
      collection || new MedicolegalInvestigationCollection();

    return this;
  }

  public addFuneralHome(funeralHome?: FuneralHome) {
    if (this.funeralHome) throw new Error('A funeral home already exists.');

    this.funeralHome = funeralHome || new FuneralHome();

    return this;
  }

  public addInfectiousDisease(infectiousDisease?: InfectiousDisease) {
    if (this.infectiousDisease)
      throw new Error('Infectious disease already exists.');

    this.infectiousDisease = infectiousDisease || new InfectiousDisease();

    return this;
  }

  public addNextOfKinCollection(nextOfKinCollection?: NextOfKinCollection) {
    if (this.nextOfKin)
      throw new Error('Next of kin collection already exists.');

    this.nextOfKin = nextOfKinCollection || new NextOfKinCollection();

    return this;
  }

  public addNotesCollection(notes?: NotesCollection) {
    if (this.notes) throw new Error('A notes collection already exists.');

    this.notes = notes || new NotesCollection();

    return this;
  }

  public addDocumentsCollection(collection?: DocumentCollection) {
    if (this.documents)
      throw new Error('A documents collection already exists.');

    this.documents = collection || new DocumentCollection();

    return this;
  }
}
