import { DateTimeInfo, IsDateTimeInfo } from '../dates';
import { RequirementStatus } from './requirement-status';

export class MedicalExaminer {
  public requirementStatus?: RequirementStatus;

  public medicalExaminerId?: string;

  @IsDateTimeInfo()
  public dateNotified?: DateTimeInfo;

  @IsDateTimeInfo()
  public dateCompleted?: DateTimeInfo;

  constructor(args?: MedicalExaminer) {
    this.requirementStatus = args?.requirementStatus;
    this.medicalExaminerId = args?.medicalExaminerId;
    this.dateNotified = args?.dateNotified
      ? new DateTimeInfo(args?.dateNotified)
      : undefined;
    this.dateCompleted = args?.dateCompleted
      ? new DateTimeInfo(args?.dateCompleted)
      : undefined;
  }
}
