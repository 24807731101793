import { Type } from 'class-transformer';
import { FormatDate, IsFormatDate } from '../../dates';
import { CaseAction } from '../case-action';
import { CaseNotice } from '../notice';
import { CaseSummaryTags } from './tags';
import { OriginLocationSummary } from './origin-location-summary';

export interface CaseTypeSummary {
  readonly name: string;
  readonly critical: boolean;
}

export class ReservationCaseSummary {
  public readonly id!: string;

  public readonly decedentName?: string;

  public readonly decedentAlias?: string;

  public readonly decedentMedicalRecordNumber?: string;

  @IsFormatDate
  public readonly decedentDateOfBirth?: FormatDate;

  @IsFormatDate
  public readonly decedentExpirationDate?: FormatDate;

  public readonly caseType?: CaseTypeSummary;

  public readonly originLocation?: OriginLocationSummary;

  @Type(() => CaseSummaryTags)
  public readonly tags!: CaseSummaryTags;

  @Type(() => CaseAction)
  public readonly checkIn!: CaseAction;

  @Type(() => CaseNotice)
  public readonly notices!: CaseNotice[];
}
