













import Vue from 'vue';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import DOMPurify from 'dompurify';
import { Editor, EditorContent as TiptapEditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import { Color } from '@tiptap/extension-color';

export const Props = {
  value: {
    type: String as PropType<string | null>,
    default: null,
  },
  mode: {
    type: String as PropType<'html' | 'json'>,
    default: 'html',
  },
  placeholder: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },
  readonly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
} as const;

export default defineComponent({
  name: 'EditorContent',
  components: {
    TiptapEditorContent,
  },
  props: {
    ...Props,
  },
  setup(props, { emit }) {
    const editor = ref<Editor>();
    const content = ref<Vue>();

    const sanitizedValue = computed(() => {
      if (props.mode === 'json') return editor.value?.getJSON();

      const html = editor.value?.getHTML();
      return html ? sanitizeHtml(html) : null;
    });

    const isEmpty = computed(() => {
      if (!editor.value) return true;

      // Get the JSON content of the editor
      const json = editor.value.getJSON();

      // Check if there's only one empty paragraph node
      if (
        json.content?.length === 1 &&
        json.content[0].type === 'paragraph' &&
        (!json.content[0].content || json.content[0].content.length === 0)
      ) {
        return true;
      }

      // Check if there's no content at all
      return !json.content || json.content.length === 0;
    });

    function focus(): void {
      const root = content.value?.$el;
      if (!root) return;
      const input = root.querySelector(".tiptap[contenteditable='true']");
      if (!input) return;
      (input as HTMLDivElement).focus();
    }

    function emitChange() {
      emit('input', sanitizedValue.value);
      emit('change', sanitizedValue.value);
    }

    onMounted(() => {
      editor.value = new Editor({
        content: props.value ? sanitizeHtml(props.value) : null,
        editable: !props.readonly,
        extensions: [
          StarterKit,
          TextStyle,
          Color,
          Underline,
          Placeholder.configure({ placeholder: props.placeholder }),
          Highlight.configure({ multicolor: true }),
          Link.configure({
            openOnClick: 'whenNotEditable',
            defaultProtocol: 'https',
          }),
        ],
        onUpdate: emitChange,
        onFocus: ({ event }) => emit('focus', event, sanitizedValue.value),
        onBlur: ({ event }) => emit('blur', event, sanitizedValue.value),
        editorProps: {
          attributes: {
            class: 'prose w-full focus:outline-none',
          },
        },
      });
    });

    onUnmounted(() => editor.value?.destroy());

    watch(
      () => props.value,
      (value) => {
        if (!editor.value) return;

        const isSame =
          props.mode === 'html'
            ? editor.value.getHTML() === value
            : JSON.stringify(editor.value.getJSON()) === JSON.stringify(value);

        if (isSame) return;

        editor.value.commands.setContent(value, false);
      }
    );

    watch(
      () => props.readonly,
      (readonly) => editor.value?.setEditable(!readonly)
    );

    function sanitizeHtml(html: string) {
      return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } });
    }

    return { editor, content, focus, isEmpty };
  },
});
