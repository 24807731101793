import { DictionaryItem } from './dictionary-item';

export class FluidLocationDictionaryItem extends DictionaryItem {
  public readonly triggersInsight: boolean;

  constructor(args?: FluidLocationDictionaryItem) {
    super(args);
    this.triggersInsight = args?.triggersInsight ?? false;
  }
}
