import { CaseService } from './case-service';

export enum CaseServiceRequirementType {
  Unknown = 0,
  Required = 1,
  Excluded = 2,
}

export class CaseServiceCollection<TService extends CaseService> {
  public readonly requirementId?: string;

  public readonly instances!: TService[];

  constructor(args?: Partial<CaseServiceCollection<TService>>) {
    this.requirementId = args?.requirementId;
    this.instances = args?.instances ?? [];
  }
}
