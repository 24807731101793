export const EventType = {
  IntegrationMessageReceived: 'Message Received',
  CaseViewed: 'Case Viewed',
  DocumentDownloaded: 'Document Downloaded',
  CaseCreated: 'Case Created',
  CaseUpdated: 'Case Updated',
  CaseOpened: 'Case Opened',
  CaseClosed: 'Case Closed',
  CaseTouched: 'Case Touched',
  CaseDocumentUploaded: 'Document Uploaded',
  CaseHoldAdded: 'Case Hold Added',
  CaseHoldRemoved: 'Case Hold Removed',
  CaseNoteAdded: 'Case Note Added',
  CaseNoteModified: 'Case Note Modified',
  CaseNoteRemoved: 'Case Note Removed',
  DecedentCheckedIn: 'Decedent Checked In',
  DecedentTransferred: 'Decedent Transferred',
  DecedentCheckedOut: 'Decedent Checked Out',
  NextOfKinIdentified: 'Next Of Kin Identified',
  NextOfKinNotified: 'Next Of Kin Notified',
  AutopsyStatusUpdated: 'Autopsy Status Updated',
  AutopsyCompleted: 'Autopsy Completed',
  MedicolegalInvestigationStatusUpdated: 'Investigation Status Updated',
  MedicolegalInvestigationCompleted: 'Investigation Completed',
  DonationProcurementStatusUpdated: 'Donation Procurement Status Updated',
  DonationProcurementCompleted: 'Donation Procurement Completed',
  FuneralHomeNotified: 'Funeral Home Notified',
} as const;

// eslint-disable-next-line no-redeclare
export type EventType = typeof EventType[keyof typeof EventType];
