import Vue from 'vue';

export type VueGetter = () => Vue;

export class EventBus<TEventName extends string> {
  private readonly busGetter: VueGetter;

  private get bus() {
    return this.busGetter();
  }

  constructor(emitter: Vue | VueGetter) {
    this.busGetter = typeof emitter === 'function' ? emitter : () => emitter;
  }

  public on(event: TEventName, callback: Function) {
    this.bus.$on(event, callback);
  }

  public once(event: TEventName, callback: Function) {
    this.bus.$once(event, callback);
  }

  public off(event?: TEventName, callback?: Function) {
    this.bus.$off(event, callback);
  }
}
