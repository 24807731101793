

















































import Vue from 'vue';
import {
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRefs,
} from '@nuxtjs/composition-api';
import FloatingVue, { Dropdown, Placement } from 'floating-vue';
import 'floating-vue/dist/style.css';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { genId } from '~/app/utils/id';
import { getFocusables } from '~/app/utils/dom';
import { sleep } from '~/app/utils/async';

// TODO: To make this composable triggers and auto-hide should be configurable

Vue.use(FloatingVue, {
  themes: {
    'mb-menu': {
      $extend: 'dropdown',
    },
    'mb-menu_compact': {
      $extend: 'dropdown',
    },
    'mb-menu--dark': {
      $extend: 'dropdown',
    },
    'mb-menu--dark_compact': {
      $extend: 'dropdown',
    },
  },
});

const placements: Readonly<Array<Placement>> = [
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
] as const;
type WindowBreakPoint = 'mobile' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
const breakpoints: Readonly<Array<WindowBreakPoint>> = [
  'mobile',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
] as const;

type DropdownTrigger = 'click' | 'hover' | 'focus' | 'touch';
const dropdownTriggers: Readonly<Array<DropdownTrigger>> = [
  'click',
  'hover',
  'focus',
  'touch',
] as const;

interface DropdownInstance {
  readonly show: Function;
  readonly hide: Function;
  readonly $refs: {
    popper: { isShown: boolean };
  };
}

export default defineComponent({
  name: 'DateTimePickerCalendar',
  components: { Dropdown },
  props: {
    /** Controls whether the menu is open. */
    isOpen: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    /** Menu placement. Use object syntax to control placement across window breakpoints. */
    placement: {
      type: [String, Object] as PropType<
        Placement | Record<WindowBreakPoint, Placement>
      >,
      required: false,
      default: 'bottom',
      validator: (placement: Placement | object) =>
        typeof placement === 'string'
          ? placements.includes(placement)
          : Object.keys(placement).every((bp) =>
              breakpoints.includes(bp as WindowBreakPoint)
            ) && Object.values(placement).every((p) => placements.includes(p)),
    },
    /** Triggers used to activate the menu. */
    triggers: {
      type: Array as PropType<DropdownTrigger[]>,
      required: false,
      default: undefined,
      validator: (triggers: DropdownTrigger[] | unknown) =>
        Array.isArray(triggers)
          ? triggers.every((trigger) => dropdownTriggers.includes(trigger))
          : false,
    },
    /** Padding between the arrow and trigger. */
    arrowOffset: {
      type: Number as PropType<number>,
      required: false,
      default: undefined,
    },
    /** Menu height. */
    height: {
      type: [String, Object] as PropType<
        string | Record<WindowBreakPoint, string>
      >,
      required: false,
      default: 'max-content',
      validator: (height: string | object) =>
        typeof height === 'string'
          ? true
          : Object.keys(height).every((bp) =>
              breakpoints.includes(bp as WindowBreakPoint)
            ),
    },
    /** Menu width. */
    width: {
      type: [String, Object] as PropType<
        string | Record<WindowBreakPoint, string>
      >,
      required: false,
      default: 'max-content',
      validator: (width: string | object) =>
        typeof width === 'string'
          ? true
          : Object.keys(width).every((bp) =>
              breakpoints.includes(bp as WindowBreakPoint)
            ),
    },
    /** Selector: Container where the popper will be appended. @default 'body' */
    container: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /** Use a drawer to display menu content on mobile screens. */
    mobile: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /** Optional element that will receive focus when the menu opens. */
    focusEl: {
      type: HTMLElement as PropType<HTMLElement>,
      required: false,
      default: null,
    },
    theme: {
      type: String as PropType<
        'light' | 'dark' | 'light-compact' | 'dark-compact'
      >,
      required: false,
      default: 'light',
    },
    triggerId: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    keyboardNavigation: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    manageFocus: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: {
    open: (shown: any) => typeof shown === 'boolean',
    'update:isOpen': (shown: any) => typeof shown === 'boolean',
  },
  setup(props, { emit }) {
    const triggerIdResolved =
      props.triggerId || `wb-calendar_trigger-${genId({ length: 7 })}`;
    const menuId = `wb-calendar_trigger_menu-${genId({ length: 7 })}`;

    const dropdownRef = ref<DropdownInstance>();
    const triggerEl = ref<HTMLElement | null>();

    const instance = getCurrentInstance();
    function getTriggerEl() {
      return (
        instance?.proxy.$el.querySelector<HTMLElement>(
          `#${triggerIdResolved}`
        ) ?? document.getElementById(triggerIdResolved)
      );
    }

    onMounted(() => (triggerEl.value = getTriggerEl()));

    const isShown = computed(
      () => !!dropdownRef.value?.$refs?.popper?.isShown ?? false
    );

    // #region Position
    const bps = useBreakpoints(breakpointsTailwind);
    const getCurrentBreakpoints = () => {
      const points = Object.keys(breakpointsTailwind).map(
        (bp) =>
          [bp, bps.greater(bp as keyof typeof breakpointsTailwind)] as const
      );
      return computed(() => points.filter(([, v]) => v.value).map(([k]) => k));
    };
    const currentBreakpoints = getCurrentBreakpoints();
    const syncWithBreakpoint = <T extends string>(
      value: T | Record<WindowBreakPoint, T>,
      defaultValue: T
    ) => {
      if (typeof value === 'string') return value;

      const _breakpoints = Object.keys(value).sort(
        (a, b) =>
          breakpoints.indexOf(a as WindowBreakPoint) -
          breakpoints.indexOf(b as WindowBreakPoint)
      );

      for (const bp of [...currentBreakpoints.value].reverse()) {
        if (_breakpoints.includes(bp)) return value[bp as WindowBreakPoint];
      }

      return value.mobile || defaultValue;
    };

    const placementComputed = computed(() =>
      syncWithBreakpoint(props.placement, 'bottom')
    );
    // #endregion

    const widthComputed = computed(() =>
      syncWithBreakpoint(props.width, 'max-content')
    );

    const heightComputed = computed(() =>
      syncWithBreakpoint(props.height, 'max-content')
    );

    // #region Mobile
    const { mobile, focusEl } = toRefs(props);
    const isMobileScreen = computed(() => !currentBreakpoints.value.length);
    const isMobile = computed(() => mobile.value && isMobileScreen.value);
    // #endregion

    function onArrowDown(e: KeyboardEvent) {
      e.preventDefault();
      e.stopImmediatePropagation();

      const menuEl = document.querySelector<HTMLElement>(`#${menuId}`);
      if (!menuEl) return;

      const focusableEls = getFocusables(menuEl, true);
      const focusedIndex = focusableEls.findIndex((el) => el === e.target);

      if (focusedIndex === -1) return;

      let nextIndex =
        e.key === 'ArrowDown'
          ? focusedIndex + 1
          : e.key === 'ArrowUp'
          ? focusedIndex - 1
          : focusedIndex;

      // Cycle back to the beginning if we reach the end
      if (e.key === 'ArrowDown' && nextIndex > focusableEls.length - 1)
        nextIndex = 0;

      // Cycle to the end if we reach the beginning
      if (e.key === 'ArrowUp' && nextIndex < 0)
        nextIndex = focusableEls.length - 1;

      const nextEl = focusableEls[nextIndex];
      if (nextEl) nextEl.focus();
    }

    function onTabDown(e: KeyboardEvent) {
      const triggerEl = getTriggerEl();
      if (document.activeElement === triggerEl) return;

      e.preventDefault();
      e.stopImmediatePropagation();

      // Return focus to the trigger if tabbing out of the menu

      const menuEl = document.querySelector<HTMLElement>(`#${menuId}`);
      if (!menuEl) return;

      const isMenuFocused = menuEl.contains(document.activeElement);

      if (isMenuFocused) return hide(true);
    }

    function onEscapeDown(e: KeyboardEvent) {
      e.stopImmediatePropagation();

      // Close the menu and return focus to the trigger
      hide(true);
    }

    function show() {
      dropdownRef.value?.show();
    }

    function hide(focusTrigger = false) {
      dropdownRef.value?.hide();
      focusTrigger && getTriggerEl()?.focus();
    }

    function onShow(shown: boolean) {
      emit('open', shown);
      emit('update:isOpen', shown);
    }

    function onMobileShow() {
      document.body.classList.add('popper-no-scroll');
    }

    function onMobileHide() {
      document.body.classList.remove('popper-no-scroll');
    }

    async function onShown() {
      if (isMobile.value) onMobileShow();

      if (!props.manageFocus) return;

      const isHTMLElement = focusEl.value instanceof HTMLElement;
      if (focusEl.value && isHTMLElement) {
        // Delay 100ms to account for opening transition
        await nextTick();
        await sleep(100);

        focusEl.value.focus();
        return;
      }

      // If no focus element is provided, focus the first focusable element in the menu
      const menuEl = document.querySelector<HTMLElement>(`#${menuId}`);
      if (menuEl) {
        const focusableEls = getFocusables(menuEl, true);

        // If no focusable elements are found, re-focus the trigger itself
        if (!focusableEls.length) {
          const triggerEl = getTriggerEl();

          // Delay 100ms to account for opening transition
          await nextTick();
          await sleep(100);
          return triggerEl?.focus();
        }

        const firstItem = focusableEls[0];

        // Delay 100ms to account for opening transition
        await nextTick();
        await sleep(100);
        firstItem?.focus();
      }
    }

    return {
      triggerIdResolved,
      menuId,
      dropdownRef,
      isShown,
      placementComputed,
      widthComputed,
      heightComputed,
      isMobile,
      show,
      hide,
      onTabDown,
      onEscapeDown,
      onArrowDown,
      onShow,
      onMobileShow,
      onMobileHide,
      onShown,
    };
  },
});
