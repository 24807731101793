export class InfectiousDisease {
  public description?: string;
  public diseaseTypeIds!: string[];

  constructor(args?: InfectiousDisease) {
    if (!args) return;
    this.description = args?.description;
    this.diseaseTypeIds = args?.diseaseTypeIds;
  }
}
